import {Link} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import FormulaContext from "../Context/F1Context";

const TopMenuNew = ({season})=>{
    const {getNextStageId, nextGp} = useContext(FormulaContext);

    useEffect(() => {
        //getNextStageId();
    }, []);

    if(!season){season = 5}
    return(
        <div className="menu-bg" id='menu-color'>
            <Link className="menu-button" to={`/`}>Home</Link>
            <span>|</span>
            <Link className="menu-button" to={`/f1`}>Main</Link>
            <span>|</span>
            <Link className="menu-button" to={`/stage/${nextGp[0] ? nextGp[0].stage : ''}`}>Next GP</Link>

            <span>|</span>

            {/*<Link className="menu-button" to={`/season/${season}`}>Season {season}</Link>*/}
            <Link className="menu-button" to={`/calendar/${season}`}>Calendar</Link>
            <span>|</span>
            <Link className="menu-button" to={`/pilotstanding/${season}`}>Driver</Link>
            <span>|</span>
            <Link className="menu-button" to={`/constructorstanding/${season}`}>Constructor</Link>
            <span>|</span>
            <Link className="menu-button" to={`/weatherchoose`}>Weather</Link>
            {/*<Link className="menu-button" to={`/pilots/${season}`}>Pilots</Link>*/}
            {/*<span>|</span>*/}
            {/*<Link className="menu-button" to={`/rating`}>Rating</Link>*/}
        </div>
    )
}
export default TopMenuNew;