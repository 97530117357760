import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatarPos from "./GetAvatarPos";

import './Content2.scss';
import './WebStartingGrid.scss';
import './WebStartingGridMain.scss';
import {logDOM} from "@testing-library/react";



const StartingGrid2 = ({start, track, season, scale, mLeft, mTop}) => {
    let params = useParams();
    const {getStartRace, racestartLoading, racestart} = useContext(FormulaContext);
// const startReverse = [...start].reverse();
    //console.log(start)
    //
    // const startlist = _.sortBy(startReverse, [function (o) {
    //     return o.race.start;
    // }]);

    const newStartList = (list) => {
        let newList = []
        console.log(list.length)
        const empty = 20 - list.length
        if(empty>0){
            for(let i = 1; i<=empty; i++){
                newList.push({
                    position: 21-i,
                    pilot: {avatar: [{url: "/uploads/Terry_4_1_250af8c0dc.png"}]},
                    team: 'empty',
                    name: 'nickname',
                    firstname: 'name',
                    number: '00',

                })
            }
        }
        list.map((driver)=>{

            newList.push({
                position: driver.race.start,
                pilot: driver.pilot.pilot,
                team: driver.pilot.team,
                name: driver.pilot.pilot.Name,
                firstname: driver.pilot.pilot.firstname,
                number: driver.pilot.pilot.number,

            })
        })
        return newList;
    }

    console.log(newStartList(start))
    const startList = newStartList(start);

    const stratlistordered = _.orderBy(startList, ['position'], ['desc'])
    console.log(stratlistordered)
    useEffect(() => {
        getStartRace();

    }, []);

    if (racestartLoading ) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }


    //console.log(racestart[0].racestart)
    let line = 11;
    let line2 = 11;
    let line3 = 11;
    let line4 = 11;
    let lineReversLeft = 0;
    let positionDouble = 22;
    let lineReversLeft2 = 0;
    let positionDouble2 = 21;
    return (
        <div className="web-calendar web-calendar-start-grid trans" style={{transform: `scale(${scale}) rotate(90deg)`,  marginLeft: `${mLeft}px`, marginTop: `${mTop}px`}} >


            <div className="web-calendar-container web-calendar-container-start-grid">

                <div className="web-title-text">STARTING GRID</div>
                <div className="web-big-x"></div>
                <div className="web-radial-big-bg"></div>
                <div className="web-smoke-down-bg"></div>
                <div className="web-darknes-bg"></div>
                <div className="web-bottom-darknes-bg"></div>




                <div className="web-left-container">
                    <div className="web-left-side">

                        {
                            stratlistordered.map((driver,index)=>{
                                if(index % 2 === 0){
                                    line2--;
                                    return (
                                        <div className={`background-f1-left background-f1-left-${driver.team.shorttitle} background-f1-left-line${line2}`}></div>
                                    )
                                }
                            })
                        }

                    </div>

                    {


                        stratlistordered.map((driver,index)=>{

                            if(index % 2 === 0){
                                line--;
                                positionDouble = positionDouble-2;
                                return (
                                    <div key={index}>
                                    <div className={`web-driver-avatar driver-line${line}`}><img src={`${process.env.REACT_APP_ADMIN_URL}${GetAvatarPos(driver.pilot.avatar, season, 2)}`} alt="" className="web-driver-avatar-img"/></div>
                                        <div className={`web-radial-bg web-radial-bg-${driver.team.shorttitle} driver-line${line}`}></div>
                                        <div className={`web-team-name-vertical driver-line${line}`}>{driver.team.name}</div>
                                        <div className={`web-pilot-number outline driver-line${line}`}>{driver.pilot.number}</div>
                                        <div className={`place-name-holder-left driver-line${line}`}>
                                                <div className="place">
                                                    <span className="number">{positionDouble}</span>
                                                    <span className="st">TH</span>
                                                </div>
                                                <div className="name">
                                                    <div className="short">{driver.pilot.firstname}</div>
                                                    <div className={`nickname nickname-${driver.team.shorttitle}`}>{driver.pilot.Name}</div>
                                                </div>
                                    </div>
                                    </div>
                                )




                            }
                        })
                    }










                </div>






                <div className="web-right-container">
                    <div className="web-right-side">

                        {
                            stratlistordered.map((driver,index)=>{
                                if(index % 2 != 0){
                                    line4--;
                                    return (
                                        <div className={`background-f1-right background-f1-right-${driver.team.shorttitle} background-f1-right-line${line4}`}></div>
                                    )
                                }
                            })
                        }


                    </div>


                    {


                        stratlistordered.map((driver2,index)=>{

                            if(index % 2 != 0){
                                line3--;
                                positionDouble2 = positionDouble2-2;
                                return (
                                    <div key={index}>
                                        <div className={`web-driver-avatar driver-line${line3}`}><img src={`${process.env.REACT_APP_ADMIN_URL}${GetAvatarPos(driver2.pilot.avatar, season, 3)}`} alt="" className="web-driver-avatar-img"/></div>
                                        <div className={`web-radial-bg web-radial-bg-${driver2.team.shorttitle} driver-line${line3}`}></div>
                                        <div className={`web-team-name-vertical-right driver-line${line3}`}>{driver2.team.name}</div>
                                        <div className={`web-pilot-number outline driver-line${line3}`}>{driver2.pilot.number}</div>
                                        <div className={`place-name-holder-right driver-line${line3}`}>

                                            <div className="name">
                                                <div className="short">{driver2.pilot.firstname}</div>
                                                <div className={`nickname nickname-${driver2.team.shorttitle}`}>{driver2.pilot.Name}</div>
                                            </div>
                                            <div className="place">
                                                <span className="number">{positionDouble2}</span>
                                                <span className="st">TH</span>
                                            </div>
                                        </div>
                                    </div>
                                )




                            }
                        })
                    }








                </div>
                <div className="web-grid-wrapper">
                    <div className="grid-line-main"></div>
                </div>
                <div className="web-grid-list-rows">

                    <div className="left-col">
                        {
                            stratlistordered.map((driver,index)=>{
                                if(index % 2 === 0){
                                    lineReversLeft++;
                                    if(lineReversLeft == 10) lineReversLeft = 0;
                                    return (
                                        <div className={`col-left l-${lineReversLeft}`}>{driver.pilot.Name}</div>
                                    )
                                }
                            })
                        }



                    </div>
                    <div className="right-col">
                        {
                            stratlistordered.map((driver,index)=>{
                                if(index % 2 != 0){
                                    lineReversLeft2++;
                                    if(lineReversLeft2 == 10) lineReversLeft2 = 0;
                                    return (
                                        <div className={`col-right r-${lineReversLeft2}`}>{driver.pilot.Name}</div>
                                    )
                                }
                            })
                        }

                    </div>

                </div>






            </div>
        </div>
    )
}

export default StartingGrid2;