import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import {Routes, Route, useParams} from 'react-router-dom';
//import './Content.scss';
import './Content2.scss';
//import './Web.scss';
import './MainTableAvatars.scss';
import {Link} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";

import moment from 'moment';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from '@mui/material/useMediaQuery';
import GetAvatar from "./GetAvatar";
import PilotStanding2 from "./PilotStanding 2";
import StandingTable from "./StandingTable";
import CalendarOnMain from "./CalendarOnMain";
import TopMenuNew from "./TopMenuNew";
import TopMenuNewMobile from "./TopMenuNewMobile";
import WebRacePodium from "./WebRacePodium";
import PilotsForSeasonOnMain from "./PilotsForSeasonOnMain";
import TopMenuSeasons from "./TopMenuSeasons";
import StandingTableMobile from "./StandingTableMobile";
import PilotsForSeasonOnMainMobile from "./PilotsForSeasonOnMainMobile";

const Content = () => {
    const {seasons, getSeason, getNextStageId, getSeasonRes, allResults, getAllRes, nextGp, seasonsLoaded} = useContext(FormulaContext);
    const [activeClass, setActiveClass] = useState(2);
    const [isResults, setIsResults] = useState (false);
    const matches = useMediaQuery('(max-width:450px)');
    const matches800 = useMediaQuery('(max-width:850px)');
    let params = useParams();


    //console.log(seasonNumber)
    useEffect(() => {
        getSeason();
        getAllRes();
    }, []);
    useEffect(() => {
        getNextStageId();

    }, []);

    console.log(seasons)


    const SeasonHover = (id) => {
        setActiveClass(id);
    }
    const SeasonOut = () => {

    }




    if (seasonsLoaded) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )

    }
    if (!seasonsLoaded) {


        let seasonNumber = seasons[seasons.length - 1].id;
        // if (!params.IdSeason){
        //     console.log(params.IdSeason= seasons[seasons.length - 1].id);
        // }

        // console.log(allResults)
        // console.log(seasonNumber)
        // console.log(isResults)


        allResults.forEach((stageResult)=>{

            //console.log(stageResult)
            if(stageResult){
                if(stageResult.resultlist.length > 0) {
                    if (parseInt(stageResult.season) == parseInt(seasonNumber)) {
                        console.log(parseInt(stageResult.season) == parseInt(seasonNumber))
                        if (!isResults) {
                            setIsResults(true);
                        }
                    }
                }
            }
        })
        if(matches800) {
            return (
                <div className="contentmobile">
                    <div className="contentmobile-container">

                        <div className="contentmobile-title-text">PTP League Racing <br/>{seasonNumber} Season</div>

                        <TopMenuNewMobile season={seasonNumber} />
                        <TopMenuSeasons  seasons={seasons} season={seasonNumber} />

                        {isResults ? <StandingTableMobile idSeason={seasonNumber}/> : <PilotsForSeasonOnMainMobile idSeason={seasonNumber} limit={10} />}

                        <div className="contentmobile-title-text">Calendar for {seasonNumber} Season</div>
                        <CalendarOnMain seasonNumber={seasonNumber} />




                    </div>

                </div>


            )
        }
        return (
            <div className="content">
                <div className="content-container">

                    <div className="content-title-text">PTP League Racing {seasonNumber} Season</div>

                    <TopMenuNew season={seasonNumber} />
                    <TopMenuSeasons  seasons={seasons} season={seasonNumber} />

                    {isResults ? <StandingTable idSeason={seasonNumber}/> : <PilotsForSeasonOnMain idSeason={seasonNumber} limit={10} />}

                    <div className="content-title-text">Calendar for {seasonNumber} Season</div>
                    <CalendarOnMain seasonNumber={seasonNumber} />




                </div>

            </div>
        )
    }
}
export default Content;