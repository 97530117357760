import React, {useContext, useEffect, useMemo} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";
import './Content2.scss';
//import './Pilots.scss';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import TopMenu from "./TopMenu";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CachedIcon from '@mui/icons-material/Cached';
import TopMenuNew from "./TopMenuNew";
import TopMenuSeasons from "./TopMenuSeasons";
import StandingTable from "./StandingTable";
import PilotsForSeasonOnMain from "./PilotsForSeasonOnMain";
import CalendarOnMain from "./CalendarOnMain";

const WeatherChoose= () => {
    let params = useParams();

// let randomWeather = useMemo(()=>{
//     return Math.floor(Math.random() * 100)
// }, []);

    const {stage, getStageRes, stageloading, dispatch} = useContext(FormulaContext);

    const [trackCoef, settrackCoef] = React.useState('');
    const [value, setValue] = React.useState(0);
    const [randomWeather, setReandomWeather] = React.useState('');
    const [visible, setVisible] = React.useState(false);

    const handleChange = (event) => {
        setReandomWeather('');
        setVisible(false);
        settrackCoef(event.target.value);
    };

    useEffect(() => {
        getStageRes(params.IdStage);

    }, []);
    let ratingList = [];

    if (stageloading) return (<div className="calendar">

        <div className="thumbnail-list"><CircularProgress color="inherit"/></div>
    </div>)




    if (stageloading === false) {
        //console.log(allResults)

        let weatherWeekend = {
            PredictWeekend: '',
            PracticeWeatherP1: '',
            PracticeWeatherP2: '',
            PracticeWeatherP3: '',
            PracticeWeatherP4: '',
            WeatherQ1: '',
            WeatherQ2: '',
            WeatherQ3: '',
            RaceWeatherP1: '',
            RaceWeatherP2: '',
            RaceWeatherP3: '',
            RaceWeatherP4: '',
        }
    const differentWeather = [0,10,20,30,40,50,60,70];
    const randomdifferentweather = Math.floor(Math.random()*7);

        const weathercoef = differentWeather[randomdifferentweather]/100;

        const getRandomFromTo = (mn, mx) => {

            const max = mx <101 ? mx : 100;
            const min = mn > -1 ? mn : 0;
            return Math.floor(Math.random() * (max - min) + min);
        }

        const getWeatherChanges = (randomstart) =>{


            const rw1 = randomstart;
            const rw2 = getRandomFromTo(rw1-10, rw1+10);
            const rw3 = getRandomFromTo(rw2-30, rw2+30);
            const rw4 = getRandomFromTo(rw3-10, rw3+10);
            const rw5 = getRandomFromTo(rw4-10, rw4+10);
            const rw6 = getRandomFromTo(rw5-30, rw5+30);
            const rw7 = getRandomFromTo(rw6-10, rw6+10);
            const rw8 = getRandomFromTo(rw7-10, rw7+10);
            const rw9 = getRandomFromTo(rw8-10, rw8+10);



            return {
                rw1: rw1,
                rw2: rw2,
                rw3: rw3,
                rw4: rw4,
                rw5: rw5,
                rw6: rw6,
                rw7: rw7,
                rw8: rw8,
                rw9: rw9,
            };

        }

        // console.log('-', getWeatherChanges(randomWeather))

        // console.log('000000000000000000000000000000000000000000000000000000000')
        // console.log('randomWeather', randomWeather, 'randomdifferentweather', randomdifferentweather, 'differentWeather[randomdifferentweather]', differentWeather[randomdifferentweather], 'weathercoef', weathercoef )
        // console.log('randomWeather*weathercoef', randomWeather*weathercoef);
        // console.log('randomWeather/weathercoef', randomWeather/weathercoef);
        // console.log('weathercoef/randomWeather', weathercoef/randomWeather);
        // console.log('weathercoef',weathercoef)
        // console.log('randomWeather/100',randomWeather/100);
        // console.log('(randomWeather/100)*weathercoef',(randomWeather/100)*weathercoef)
        // console.log('(randomWeather/100)+weathercoef', (randomWeather/100)+weathercoef)
        // console.log('power rain', ((randomWeather/100)+weathercoef) - ((randomWeather/100)*weathercoef))

        // console.log('------------------------------------------------------------------------------------------------')
        // console.log(randomWeather, differentWeather[randomdifferentweather])







        const weatherPredict = (randomCoef, trackCoef ) =>{

            if(randomCoef<31){
                return 'clear';
            }
            if(randomCoef>30 && randomCoef < 61){

                if(trackCoef>49){


                    return Math.random() < 0.2 ? 'wet' : 'overcast';
                }
                if(trackCoef<50 && trackCoef>9){
                    return 'light cloud';
                }
                if(trackCoef<10) {
                    return 'clear';
                }
            }
            if(randomCoef>60 && randomCoef < 91){

                if(trackCoef>49){

                    return Math.random() < 0.6 ? 'wet' : 'very wet';
                }
                if(trackCoef<50 && trackCoef>9){
                    return Math.random() < 0.6 ? 'wet' : 'overcast';
                }
                if(trackCoef<10) {
                    return 'clear';
                }
            }


            if (randomCoef>90) {
                if (trackCoef< 10) {
                    return 'clear';
                }
                if(trackCoef<60 && trackCoef>9){
                    return 'wet';
                }
                if (trackCoef > 59) {
                    return 'very wet';
                }
            }

        }



        const handleButtonClick = () => {
            this.forceUpdate()
        }
        const forceUpdate = () =>{
            setValue(0);
            console.log('reload')

            setVisible(true);
            setReandomWeather(Math.floor(Math.random() * 100))
        }
        const randomCoefs = getWeatherChanges(randomWeather);
        // const trackCoef = differentWeather[randomdifferentweather];

        // console.log('coef lust', randomCoefs)






        const GetWeatherIcon = (weather)=>{

            if(weather == 'clear'){
                return (
                    <>
                    <img src="../icons/weather/sun.svg" className='weather-icon' alt=""/>
                        Clear
                    </>
                )
            }
            if(weather == 'light cloud'){
                return (
                    <>
                    <img src="../icons/weather/cloud.svg" className='weather-icon' alt=""/>
                        light cloud
                    </>
                )
            }
            if(weather == 'overcast'){
                return (
                    <>
                    <img src="../icons/weather/fog.svg" className='weather-icon' alt=""/>
                        overcast
                    </>
                )
            }
            if(weather == 'wet'){
                return (
                    <>
                    <img src="../icons/weather/light-rain.svg" className='weather-icon' alt=""/>
                        wet
                    </>
                )
            }
            if(weather == 'very wet'){
                return (
                    <>
                    <img src="../icons/weather/heavy-rain.svg" className='weather-icon' alt=""/>
                        very wet
                    </>
                )
            }
            if(weather == 'dynamic'){
                return (
                    <>
                    <img src="../icons/weather/no-data.svg" className='weather-icon' alt=""/>
                        dynamic
                    </>
                )
            }
        }










        return (
            <div className="content">
                <div className="content-container">

                    <div className="content-title-text">PTP League Racing Weather randomizer</div>

                    <TopMenuNew season={5} />
                    {/*<TopMenuSeasons  seasons={seasons} season={seasonNumber} />*/}

                    <div className="content-title-text">Random</div>

                    <div style={{color: '#ffffff'}}>
                    <Box sx={{ minWidth: 120, backgroundColor: 'rgba(255,255,255,0.42)' }}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label"  style={{color: '#ffffff'}}>Track</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={trackCoef}
                                label="Coef"
                                onChange={handleChange}
                            >

                                <MenuItem value={0}>Bahrain</MenuItem>
                                <MenuItem value={0.1}>Jedda</MenuItem>
                                <MenuItem value={23}>Australia</MenuItem>
                                <MenuItem value={35}>Imola</MenuItem>
                                <MenuItem value={0.3}>Miami</MenuItem>
                                <MenuItem value={17}>Spain</MenuItem>
                                <MenuItem value={26}>Monaco</MenuItem>
                                <MenuItem value={0}>Baku</MenuItem>
                                <MenuItem value={19}>Canada</MenuItem>
                                <MenuItem value={33}>Britain</MenuItem>
                                <MenuItem value={22}>Austria</MenuItem>
                                <MenuItem value={21}>France</MenuItem>
                                <MenuItem value={18}>Hungary</MenuItem>
                                <MenuItem value={34}>Belgium</MenuItem>
                                <MenuItem value={0.1}>Zandvort</MenuItem>
                                <MenuItem value={13}>Monza</MenuItem>
                                <MenuItem value={15}>Singapure</MenuItem>
                                <MenuItem value={25}>Japan</MenuItem>
                                <MenuItem value={20}>USA</MenuItem>
                                <MenuItem value={11}>Mexico</MenuItem>
                                <MenuItem value={41}>Brazil</MenuItem>
                                <MenuItem value={0.2}>Abu-Dhabi</MenuItem>
                                <MenuItem value={0.5}>Portimao</MenuItem>
                                <MenuItem value={44}>China</MenuItem>

                            </Select>
                        </FormControl>
                    </Box>



                        <div className="weather-container">
                            <div className="chance-of-rain">
                                <img src="../icons/weather/moderate-rain.svg" style={{width: '150px', height: '150px'}} alt=""/>
                                <div className="text">Chance of rain on track: <br /><div style={{fontSize: '24px', marginTop:'10px'}}>{trackCoef}%</div><br /> Good luck and have fun <br /> <span style={{fontSize: '24px'}}>{randomWeather}% </span> <button onClick={forceUpdate}>
                                    <CachedIcon />
                                </button></div>
                            </div>

                            <div className="weather-weekend">


                                <div className={`practice ${visible ? 'practice-visible' : ''}`}>
                                    <div className="title">Weather for practice</div>
                                    <div className="weather">
                                        <div className={`part1 ${visible ? 'part1-visible' : ''}`}>Part 1 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw1, trackCoef))} </div>
                                        <div className={`part2 ${visible ? 'part2-visible' : ''}`}>Part 2 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw2, trackCoef))} </div>
                                    </div>
                                </div>

                                <div className={`qualy ${visible ? 'qualy-visible' : ''}`}>
                                    <div className="title">Weather for Qualification</div>
                                    <div className="weather">
                                        <div className={`q1 ${visible ? 'q1-visible' : ''}`}>Q1 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw3, trackCoef))}</div>
                                        <div className={`q2 ${visible ? 'q2-visible' : ''}`}>Q2 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw4, trackCoef))}</div>
                                        <div className={`q3 ${visible ? 'q3-visible' : ''}`}>Q3 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw5, trackCoef))}</div>
                                    </div>
                                </div>
                                <div className={`race ${visible ? 'race-visible' : ''}`}>
                                    <div className="title">Weather for Race</div>
                                    <div className="weather">
                                        <div className={`r1 ${visible ? 'r1-visible' : ''}`}>Part 1 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw6, trackCoef))}</div>
                                        <div className={`r2 ${visible ? 'r2-visible' : ''}`}>Part 2 <br />{GetWeatherIcon(weatherPredict(randomCoefs.rw7, trackCoef))}</div>
                                        <div className={`r3 ${visible ? 'r3-visible' : ''}`}>Part 3 <br />{GetWeatherIcon(Math.random() < 0.5 ? 'dynamic' : weatherPredict(randomCoefs.rw8, trackCoef))}</div>
                                        <div className={`r4 ${visible ? 'r4-visible' : ''}`}>Part 4 <br />{GetWeatherIcon(Math.random() < 0.8 ? 'dynamic' : weatherPredict(randomCoefs.rw9, trackCoef))}</div>
                                    </div>
                                </div>

                            </div>



                        </div>






                    {/*Random rain chanse: {randomWeather}%*/}
                    {/*<button onClick={forceUpdate}>*/}
                    {/*    <CachedIcon />*/}
                    {/*</button><br/>*/}

                    {/* <br/>*/}

                    {/*Practice (Qualification if Sprint):*/}
                    {/*<br/>*/}
                    {/*Part 1: {weatherPredict(randomCoefs.rw1, trackCoef)}<br/>*/}
                    {/*Part 2: {weatherPredict(randomCoefs.rw2, trackCoef)}<br/>*/}
                    {/*Qualification (Sprint)<br/>*/}
                    {/*Q1:  {weatherPredict(randomCoefs.rw3, trackCoef)}<br/>*/}
                    {/*Q2:  {weatherPredict(randomCoefs.rw4, trackCoef)}<br/>*/}
                    {/*Q3:  {weatherPredict(randomCoefs.rw5, trackCoef)}<br/>*/}
                    {/*Race<br/>*/}
                    {/*R1:  {weatherPredict(randomCoefs.rw6, trackCoef)}<br/>*/}
                    {/*R2:  {weatherPredict(randomCoefs.rw7, trackCoef)}<br/>*/}
                    {/*R3:  {Math.random() < 0.5 ? 'dynamic' : weatherPredict(randomCoefs.rw8, trackCoef)}<br/>*/}
                    {/*R4:  {Math.random() < 0.8 ? 'dynamic' : weatherPredict(randomCoefs.rw9, trackCoef)}<br/>*/}



                    </div>
                </div>

            </div>



        )
    }
}
export default WeatherChoose;