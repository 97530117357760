import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TopMenu from "./TopMenu";
import GetAvatar from "./GetAvatar";
import Points from './Points';
import './Content2.scss';
import TopMenuNew from "./TopMenuNew";
import PilotsForSeasonOnMain from "./PilotsForSeasonOnMain";
import TopMenuSeasons from "./TopMenuSeasons";
import TopMenuNewMobile from "./TopMenuNewMobile";
import StandingTableMobile from "./StandingTableMobile";
import PilotsForSeasonOnMainMobile from "./PilotsForSeasonOnMainMobile";
import CalendarOnMain from "./CalendarOnMain";

const PilotStanding = () => {
    let params = useParams();
    const {seasons, getSeason,getSeasonRes, seasonResultsLoading, seasonResults} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [stageShow, setStageShow] = useState(0);
    const [partShow, setPartShow] = useState(1);
    const [testShow, setTestShow] = useState(false);
    const [templateShow, setTemplateShow] = useState(1);
    // const matches = useMediaQuery('(max-width:500px)');
    // const matches1900 = useMediaQuery('(max-width:1900px)');
     const matches800 = useMediaQuery('(max-width:850px)');
    // const min1800 = useMediaQuery('(min-width:1800px)');
    // const min1700 = useMediaQuery('(min-width:1700px)');
    // const min1650 = useMediaQuery('(min-width:1650px)');
    // const min1600 = useMediaQuery('(min-width:1600px)');
    // const min1500 = useMediaQuery('(min-width:1500px)');
    // const min1450 = useMediaQuery('(min-width:1450px)');
    // const min1400 = useMediaQuery('(min-width:1400px)');
    // const min1350 = useMediaQuery('(min-width:1350px)');
    // const min1300 = useMediaQuery('(min-width:1300px)');
    // const min1200 = useMediaQuery('(min-width:1200px)');
    // const min1100 = useMediaQuery('(min-width:1100px)');
    // const min1000 = useMediaQuery('(min-width:1000px)');
    // const min950 = useMediaQuery('(min-width:950px)');
    // const min900 = useMediaQuery('(min-width:900px)');
    // const min850 = useMediaQuery('(min-width:850px)');
    // const min800 = useMediaQuery('(min-width:800px)');
    // const min700 = useMediaQuery('(min-width:730px)');
    // const min650 = useMediaQuery('(min-width:680px)');
    // const min600 = useMediaQuery('(min-width:630px)');
    // const min550 = useMediaQuery('(min-width:570px)');
    // const min501 = useMediaQuery('(min-width:501px)');

    const elementsCount = ()=>{return 6;}

    // const elementsCount = ()=>{
    //     if(min1800) return 25;
    //     if(min1700) return 23;
    //     if(min1650) return 22;
    //     if(min1600) return 21;
    //     if(min1500) return 19;
    //     if(min1450) return 18;
    //     if(min1400) return 17;
    //     if(min1350) return 16;
    //     if(min1300) return 15;
    //     if(min1200) return 14;
    //     if(min1100) return 12;
    //     if(min1000) return 10;
    //     if(min950) return 9;
    //     if(min900) return 8;
    //     if(min850) return 7;
    //     if(min800) return 6;
    //     if(min700) return 5;
    //     if(min650) return 4;
    //     if(min600) return 3;
    //     if(min550) return 2;
    //     if(min501) return 1;
    //     if(matches) return 1;
    // }


    const matchesmin450 = useMediaQuery('(min-width:501px)');
    ////console.log(matches1900, matchesmin450);
    let pilotlist = [];
    useEffect(() => {
        getSeasonRes(params.IdSeason);
        getSeason();
    }, [])


    const [listNumberDriver, setNumberDriver]= useState(0);
    const [stageNumber, setStageNumber] = useState(0)
    const OpenInfo = (listNumber)=>{
        if(listNumber === listNumberDriver) setNumberDriver(0);
        if(listNumber != listNumberDriver) setNumberDriver(listNumber);
        ////console.log(seasonResults.length)
        setStageNumber(seasonResults.length-1);
    }
    if (seasonResultsLoading) {
        ////console.log(seasonResults);
        return (

            <div><CircularProgress color="inherit"/></div>
        )
    }




    if (!seasonResultsLoading) {
        // if(matches800) {
        //     return (
        //         <div>
        //             Перегляд на телефоні тимчасово недоступний
        //             <CircularProgress color="inherit"/></div>
        //
        //
        //     )
        // }

        seasonResults.forEach((stage, index) => {
            ////console.log(stage);

            if(!stage.test || testShow){
                stage.resultlist.forEach((pilotRes, index) => {


                   // if(pilotRes.pilot.pilot.Name !='FASTFORWARD' && pilotRes.pilot.pilot.Name!="director" && pilotRes.pilot.pilot.Name != 'Diegu3st' && pilotRes.pilot.pilot.Name != 'PechuL' && pilotRes.pilot.pilot.Name != ' Potato'  )

                        if (pilotRes.race.points >= 0) {
                            const pilotresults = _.find(pilotlist, {pilot: pilotRes.pilot.pilot});
                            if (pilotRes.pilot.pilot.AI && pilotsAI || !pilotRes.pilot.pilot.AI) {
                                if (!pilotresults) {
                                    let p = Points(pilotRes.race.finish, (pilotRes.race.fastestlap ? 1 : 0), params.IdSeason, 0);
                                    if (pilotRes.race.dnf) p = 0;
                                    pilotlist.push({
                                        pilot: pilotRes.pilot.pilot,
                                        team: pilotRes.pilot.team,
                                        avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                        races: 1,
                                        sl: pilotRes.race.superlicense,
                                        points: p - pilotRes.race.penalty,

                                    })
                                    ////console.log( Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason))
                                }
                                if (pilotresults) {

                                    pilotlist.forEach((driver, index) => {
                                        //console.log(Points(pilotRes.race.finish, (pilotRes.race.fastestlap ? 1 : 0), params.IdSeason, templateShow))
                                        let pp = Points(pilotRes.race.finish, (pilotRes.race.fastestlap ? 1 : 0), params.IdSeason, 0);
                                        if (pilotRes.race.dnf) pp = 0;
                                        let sprintpoints;
                                        if (!pilotRes.sprint) {
                                            sprintpoints = 0;
                                        }
                                        if (pilotRes.sprint) {
                                            sprintpoints = pilotRes.sprint.points;
                                        }
                                        if (driver.pilot === pilotresults.pilot) {
                                            pilotlist[index] = {
                                                pilot: driver.pilot,
                                                avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                                team: pilotRes.pilot.team,
                                                races: driver.races + 1,
                                                sl: driver.sl + pilotRes.race.superlicense,
                                                points: driver.points + pp + sprintpoints - pilotRes.race.penalty
                                            }
                                        }
                                    })
                                }
                            }
                        }


                })
        }

        })
        const pointslist = _.sortBy(pilotlist, [function (o) {
            return o.points;
        }]);
        // const ChangeAI = () => {
        //     setPilotsAI(!pilotsAI);
        // }
        // const ChangeTest = ()=>{
        //     setTestShow(!testShow);
        // }
// const ChangeTemplate = ()=>{
//     setTemplateShow(!templateShow)
// }
//         const prevGP = () => {
//             const idGP = stageShow - 1;
//
//             setStageShow(idGP)
//         }
//         const nextGP = () => {
//             const idGP = stageShow + 1;
//
//             setStageShow(idGP)
//         }
        const prevPartGP = () => {
            const idGP = partShow - 1;

            setPartShow(idGP)
        }
        const nextPartGP = () => {
            const idGP = partShow + 1;

            setPartShow(idGP)
        }

        const sRcount = seasonResults.length;
        const p = sRcount % elementsCount() ? 1 : 0;
        //console.log(p)
        const partCount = p + Math.trunc(sRcount / elementsCount())
        const StagesColumns = ({pilotName, stages}) => {
                let partStagesList = [];
                const partStages = (part)=>{
                    seasonResults.forEach((stage, index)=>{
                        if(index < part*elementsCount() && index >= (part-1)*elementsCount()){
                            partStagesList.push(stage)
                        }
                    })
                    return partStagesList;
                }
                partStages(partShow);

                return (
                    partStagesList.map((sc, index) => {

                            if (stages) {

                                return (
                                    <Link to={`/stage/${sc.stage.stage}`} key={index}>
                                        <img src={`${process.env.REACT_APP_ADMIN_URL}${sc.track.logo.url}`} alt=""
                                             className={`country-icon ${sc.stage.done ? '' : 'not-done'}`}/>
                                    </Link>
                                );

                            }

                            const pilotCheck = _.find(sc.resultlist, function (o) {
                                return o.pilot.pilot.Name == pilotName;
                            });

                            if (!pilotCheck) {
                                return (<div className='points-gp-sized'>X</div>)
                            }

                            return (

                                <div key={index} className="points-gp-sized">{sc.resultlist.map((stg, i) => {



                                    if (!stages) {
                                        if (pilotName === stg.pilot.pilot.Name) {
                                            const poleposition = () => {
                                                if (stg.qualify) {
                                                    if (!stg.qualify) {
                                                        return ''
                                                    }
                                                    if (stg.qualify.finish === 1) {
                                                        return 'pp'
                                                    }
                                                }
                                            }
                                            const fastestlap = () => {
                                                if (stg.race.fastestlap) {

                                                    if (stg.race.fastestlap) {
                                                        return 'fl'
                                                    }
                                                }
                                            }
                                            const pp = Points(stg.race.finish, (stg.race.fastestlap ? 1 : 0), params.IdSeason,  templateShow);
                                            return (<div key={i}
                                                         className={`${(poleposition ? poleposition() : '')} ${(fastestlap ? fastestlap() : '')}`}>{stg.race.dnf ? 'DNF' : (pp - stg.race.penalty)}{stg.sprint ? `(${stg.sprint.points})` : ""}</div>)
                                        }
                                    }

                                })}</div>
                            )

                    }))

        }


        //console.log(pointslist)
        console.log(1)
        if(pointslist.length == 0) {

            if (!matches800) {

                return (
                    <div className="content">

                        <div className="content-container">
                            <div className='main-table-list'>
                                <div className="main-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                                <TopMenuNew season={params.IdSeason}/>
                                <TopMenuSeasons seasons={seasons} season={params.IdSeason}/>
                                <PilotsForSeasonOnMain idSeason={params.IdSeason} limit={30}/>

                            </div>
                        </div>
                    </div>)
            }
            if (matches800) {

                return (
                    <div className="contentmobile">
                        <div className="contentmobile-container">

                            <div className="contentmobile-title-text">PTP League Racing <br/>{params.IdSeason} Season
                            </div>

                            <TopMenuNewMobile season={params.IdSeason}/>
                            <TopMenuSeasons seasons={seasons} season={params.IdSeason}/>

                            <PilotsForSeasonOnMainMobile idSeason={params.IdSeason} limit={30}/>

                            <div className="contentmobile-title-text">Calendar for {params.IdSeason} Season</div>
                            <CalendarOnMain seasonNumber={params.IdSeason}/>


                        </div>

                    </div>


                )
            }
        }


        if(!matches800) {
            return (
                <div className="content">

                    <div className="content-container">


                        <div className='main-table-list'>
                            <div className="main-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                            <TopMenuNew season={params.IdSeason}/>
                            <TopMenuSeasons seasons={seasons} season={params.IdSeason}/>
                            {/*<div className="menu-bg" id='menu-color'>*/}
                            {/*    {seasons.map((season) => {*/}
                            {/*        return (*/}
                            {/*            <Link className="menu-button" key={season.id} to={`/season/${season.id}`} >{season.season}</Link>*/}
                            {/*        )*/}
                            {/*    }) }*/}
                            {/*</div>*/}
                            <div className="main-league-under-title">Driver Standing <span
                                className="main-season"> Season {params.IdSeason}</span></div>
                            <div className="main-league-list main-league-list-after-race">

                                <div className={`main-pilot-row`}>
                                    <div></div>
                                    <div className="main-name"></div>
                                    <div className="main-team-logo"></div>
                                    <div className="main-team"></div>
                                    <div className='header-gp-with-buttons'>
                                        <div><ArrowLeftIcon sx={{
                                            color: '#222222',
                                            padding: '0px',
                                            display: (partShow > 1 ? 'block' : 'none'),
                                            cursor: 'pointer',
                                            width: '25px'
                                        }} onClick={prevPartGP}/></div>
                                        <StagesColumns pilotName={false} stages={true}/>
                                        <div><ArrowRightIcon sx={{
                                            color: '#222222',
                                            padding: '0px',
                                            display: (partShow < partCount ? 'block' : 'none'),
                                            cursor: 'pointer',
                                            width: '25px'
                                        }} onClick={nextPartGP}/></div>
                                    </div>
                                    <div className="main-sl">SL</div>
                                    <div className="main-races">Races</div>
                                    <div className="main-points">Points</div>

                                </div>

                                {_.orderBy(pointslist, ['points'], ['desc']).map((pilot, index) => {

                                    // if(pilot.fl){
                                    //     pilotfl = pilot;
                                    // }

                                    //console.log(pilot.team)
                                    return (
                                        <div className={`main-pilot-row  main-pilot-row-after-race `} key={index}>
                                            <div className="main-place">{index + 1}</div>
                                            <div className="main-name"><span
                                                className="fn"> {pilot.pilot.firstname} </span> <span
                                                className="n"> {pilot.pilot.Name}</span></div>
                                            <div className={`main-team-logo main-team-logo-${pilot.team.shorttitle}`}>
                                                <img className="main-team-logo-img"
                                                     src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`}
                                                     alt=""/>
                                            </div>
                                            <div
                                                className={`main-team main-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>
                                            <div className="table-gp-without-buttons">
                                                <div></div>
                                                <StagesColumns pilotName={pilot.pilot.Name} stages={false}/>

                                                <div></div>
                                            </div>
                                            <div className="main-sl">{pilot.sl}</div>
                                            <div className="main-races">{pilot.races}</div>
                                            <div className="main-points">{pilot.points}</div>

                                        </div>
                                    )

                                })}
                                <div style={{fontSize: '12px', fontFamily: 'formula1 display', marginTop: '5px'}}><span
                                    className="fl"> * purple color - fastest lap</span>, <span
                                    className="pp"> underline - poleposition</span>,
                                    (points) - sprint points
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
        }
        if(matches800){
            return(
                <div className="contentmobile">

                    <div className="contentmobile-container">


                        <div className='main-table-list main-table-list-mobile'>
                            <div className="content-title-text contentmobile-title-text">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                            <TopMenuNewMobile season={params.IdSeason} />
                            <TopMenuSeasons  seasons={seasons} season={params.IdSeason} />
                            {/*<div className="menu-bg" id='menu-color'>*/}
                            {/*    {seasons.map((season) => {*/}
                            {/*        return (*/}
                            {/*            <Link className="menu-button" key={season.id} to={`/season/${season.id}`} >{season.season}</Link>*/}
                            {/*        )*/}
                            {/*    }) }*/}
                            {/*</div>*/}
                            <div className="main-league-under-title main-league-under-title-mobile">Driver Standing <span className="main-season"> Season {params.IdSeason}</span></div>
                            <div className="main-league-list main-league-list-mobile main-league-list-after-race">

                                <div className={`main-pilot-row-mobile-detailed`}>

                                    <div className="main-name"></div>
                                    <div className="main-team-logo"></div>
                                    <div className="main-team"></div>
                                    {/*<div className='header-gp-with-buttons'>*/}
                                    {/*<div><ArrowLeftIcon sx={{*/}
                                    {/*    color: '#222222',*/}
                                    {/*    padding: '0px',*/}
                                    {/*    display: (partShow > 1 ? 'block' : 'none'),*/}
                                    {/*    cursor: 'pointer',*/}
                                    {/*    width: '25px'*/}
                                    {/*}} onClick={prevPartGP}/></div>*/}
                                    {/*<StagesColumns pilotName={false} stages={true}/>*/}
                                    {/*<div><ArrowRightIcon sx={{*/}
                                    {/*    color: '#222222',*/}
                                    {/*    padding: '0px',*/}
                                    {/*    display: (partShow < partCount ? 'block' : 'none'),*/}
                                    {/*    cursor: 'pointer',*/}
                                    {/*    width: '25px'*/}
                                    {/*}} onClick={nextPartGP}/></div>*/}
                                    {/*</div>*/}
                                    {/*<div className="main-sl">SL</div><div className="main-sl">SL</div>*/}
                                    <div className="main-races">Races</div>
                                    <div className="main-points">Points</div>

                                </div>

                                {_.orderBy(pointslist, ['points'], ['desc']).map((pilot, index) => {
                                    //console.log(pointslist)
                                    const avatarFinded2 = _.find(pilot.pilot.avatar, {name: `${params.IdSeason}`});
                                    // if(pilot.fl){
                                    //     pilotfl = pilot;
                                    // }

                                    //console.log(pilot.team)
                                    return (
                                        <div key={index}>
                                            <div className={`main-pilot-row-mobile main-pilot-row-mobile-detailed main-pilot-row-after-race `} key={index} onClick={()=>{OpenInfo(index+1)}}>
                                                <div className="main-place">{index + 1}</div>
                                                {/*<div className="main-name"> <span className="fn"> {pilot.pilot.firstname} </span> <span className="n"> {pilot.pilot.Name}</span></div>*/}
                                                <div className={`main-team-logo main-team-logo-${pilot.team.shorttitle}`}>
                                                    <img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`} alt="" />
                                                </div>
                                                <div className={`main-team main-team-${pilot.team.shorttitle}`}>{pilot.pilot.Name}</div>
                                                {/*<div className={`main-team main-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>*/}
                                                {/*<div className="table-gp-without-buttons">*/}
                                                {/*    <div></div>*/}
                                                {/*    <StagesColumns pilotName={pilot.pilot.Name} stages={false}/>*/}

                                                {/*    <div></div>*/}
                                                {/*</div>*/}
                                                {/*<div className="main-sl">{pilot.sl}</div>*/}
                                                <div className="main-races">{pilot.races}</div>

                                                <div className="main-points">{pilot.points}</div>

                                            </div>
                                            <div className={`driver-info-mobile ${listNumberDriver === index+1 ? `driver-info-mobile-opened` : `driver-info-mobile-closed`} driver-info-mobile-${pilot.team.shorttitle}`}>
                                                <img src={`https://f1admin.serdyuks.com${(avatarFinded2 ? avatarFinded2.url : pilot.pilot.avatar[0].url)}`} alt="" className={`driveravatar`} />
                                                <div className="main-team-name-vertical">{pilot.team.name}
                                                    {/*{racestand[0].driver[p].team.name}*/}
                                                </div>
                                                <div className="driver-info-mobile-name">
                                                    {pilot.pilot.firstname}
                                                </div>
                                                <div className="driver-info-mobile-nickname">
                                                    {pilot.pilot.Name}

                                                </div>
                                                <div className="driver-info-mobile-number outline">{pilot.pilot.number}
                                                </div>

                                            </div>

                                        </div>
                                    )

                                })}
                                <div style={{fontSize: '12px', fontFamily: 'formula1 display', marginTop: '5px'}}><span className="fl"> * purple color - fastest lap</span>, <span
                                    className="pp"> underline - poleposition</span>,
                                    (points) - sprint points
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
        }


        /*--------------------------------------------------------------------------------*/
    }
}

export default PilotStanding;