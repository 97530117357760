import React, {useContext, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";
import './Calendar.scss';
import './PodiumAvatars.scss';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopMenuNew from "./TopMenuNew";
import GetAvatar from "./GetAvatar";
import TopMenuNewMobile from "./TopMenuNewMobile";

const Calendar = () => {
    let params = useParams();

    const {getStages, stages, stagesLoading,seasonResultsLoading, seasondetails,getSeasonRes,seasonResults, dispatch} = useContext(FormulaContext);
    const matches = useMediaQuery('(max-width:450px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');
    const matches800 = useMediaQuery('(max-width:850px)');
    useEffect(() => {
        getStages(params.IdSeason);
    }, []);
    useEffect(() => {
        getSeasonRes(params.IdSeason);
        // return function cleanup() {
        //     dispatch({type: 'CLEAR_RESULTS'});
        // };
    }, []);


    if (seasonResultsLoading) return (<div className="calendar">
        <div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>
        </div>
        <div className="thumbnail-list"><CircularProgress color="inherit"/></div>
    </div>)
    if (seasonResultsLoading === false) {
        //console.log(seasonResults)
        return (
            <div className={`content ${matches800 ? 'contentmobile' : ''} `} id="calendar">
                <div className={`content-container ${matches800 ? 'contentmobile-container' : ''} `}>
                <div className={`content-title-text ${matches800 ? 'contentmobile-title-text' : ''} `}>PTP League Racing {params.IdSeason} Season</div>

                    {matches800 ? <TopMenuNewMobile season={params.IdSeason} /> : <TopMenuNew season={params.IdSeason} /> }

                {/*<div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>*/}
                {/*</div>*/}
                <div className={`thumbnail-list ${matches800 ? 'thumbnail-list-mobile' : ''} `}>


                    {seasonResults.map((stage, index) => {
                        const date = moment(stage.stage.date, "YYYY-MM-DD");

                        if (stage.stage.done) {
                           // console.log(seasonResults);
                          // console.log(stage);
                           //  console.log(_.find(seasonResults, { stage: stage.stage }));
                            let pilotFirst;
                            let pilotSecond;
                            let pilotThird;
                            stage.resultlist.forEach((pilot,index)=>{
                                if(pilot.race.finish===1){
                                    pilotFirst = pilot.pilot;
                                }
                                if(pilot.race.finish===2){
                                    pilotSecond = pilot.pilot;
                                }
                                if(pilot.race.finish===3){
                                    pilotThird = pilot.pilot;
                                }
                            })
                            //console.log(pilotFirst, pilotSecond, pilotThird);
                            // const pilotS = _.find(stage.resultlist, { finish: 2 });
                            // const pilotF = _.find(stage.results, { finish: 1 });
                            // const pilotT = _.find(stage.results, { finish: 3 });
                            // //console.log(pilotS, pilotF, pilotT);
                            //     const pilotSecond = _.find(stage.pilots, { id: (pilotS ? pilotS.pilot: 1) });
                            //     const pilotFirst = _.find(stage.pilots, { id: (pilotF ? pilotF.pilot: 1) });
                            //     const pilotThird = _.find(stage.pilots, { id: (pilotT ? pilotT.pilot: 1) });
                           // console.log(pilotSecond, pilotFirst, pilotThird);


                            return (

                                <div className={`thumbnail-button ${matches800 ? 'thumbnail-button-mobile' : ''} `}  key={index}>
                                    <Link className="stage-link" to={`/stage/${stage.stage.stage}`}>
                                        <fieldset className="schedule-block">
                                            <legend className="stage-title">{stage.test ? 'Preseason Tests' : `Stage ${stage.stage.seasonstage}`}</legend>
                                            <div className="stage-date">
                                                <div className="text">{date.format('DD MMM')}</div>
                                                <img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.logo.url}`} alt=""
                                                     className="img"/>
                                            </div>
                                            <div className="stage-country">{stage.track.Country}</div>
                                            <div className="stage-country">{stage.laps} laps {stage.sprint ? "+ Sprint" : ""}</div>
                                            <div className="gp-name">{stage.track.title}</div>
                                            <div className="track-name">{stage.track.CircuitName}</div>


                                            <div className="podium-avatars">
                                                <div className="podium-second"><img
                                                    src={`${process.env.REACT_APP_ADMIN_URL}${pilotSecond ? GetAvatar(pilotSecond.pilot.avatar, params.IdSeason) : '/uploads/avatarf1_6ac442b41e.png'}`} alt=""
                                                    className="podium-second-img"/>
                                                    <div className="pilot-info">
                                                        <div className="pilot-team"><img
                                                            src={`${process.env.REACT_APP_ADMIN_URL}${pilotSecond ? pilotSecond.team.Logo.url : '/uploads/mclaren_b496ecac1e.png'}`} className='pilot-team-img'
                                                            alt=""/></div>
                                                        <div className="pilot-ini">{pilotSecond ? pilotSecond.pilot.shortname : ''}</div>
                                                    </div>
                                                </div>
                                                <div className="podium-first"><img
                                                    src={`${process.env.REACT_APP_ADMIN_URL}${pilotFirst ? GetAvatar(pilotFirst.pilot.avatar, params.IdSeason) : '/uploads/avatarf1_6ac442b41e.png'}`}  alt=""
                                                    className="podium-first-img"/>
                                                    <div className="pilot-info">
                                                        <div className="pilot-team"><img
                                                            src={`${process.env.REACT_APP_ADMIN_URL}${pilotFirst ? pilotFirst.team.Logo.url : '/uploads/mclaren_b496ecac1e.png'}`}
                                                            className='pilot-team-img' alt=""/></div>
                                                        <div className="pilot-ini">{pilotFirst ? pilotFirst.pilot.shortname : ''}</div>
                                                    </div>
                                                </div>
                                                <div className="podium-third"><img
                                                    src={`${process.env.REACT_APP_ADMIN_URL}${pilotThird ? GetAvatar(pilotThird.pilot.avatar, params.IdSeason) : '/uploads/avatarf1_6ac442b41e.png'}`}  alt=""
                                                    className="podium-third-img"/>
                                                    <div className="pilot-info">
                                                        <div className="pilot-team"><img
                                                            src={`${process.env.REACT_APP_ADMIN_URL}${pilotThird ? pilotThird.team.Logo.url : '/uploads/mclaren_b496ecac1e.png'}`}
                                                            className='pilot-team-img' alt=""/></div>
                                                        <div className="pilot-ini">{pilotThird ? pilotThird.pilot.shortname : ''}</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </fieldset>
                                    </Link>
                                </div>
                            )
                        }
                        if (!stage.stage.done) {
                            if(stage.stage.next){
                                if(matches){
                                    return(
                                        <div className="thumbnail-button thumbnail-button-next"  id='next-gp-calendar' key={index}>
                                            <Link className="stage-link" to={`/stage/${stage.stage.stage}`}>
                                                <fieldset className="schedule-block">
                                                    <legend className="stage-title">{stage.test ? 'Preseason Tests' : `Next Stage ${stage.stage.seasonstage}`}</legend>
                                                    <div className="stage-date">
                                                        <div className="text">{date.format('DD MMM') }</div>
                                                        <img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.logo.url}`} alt=""
                                                             className="img"/>
                                                    </div>
                                                    <div className="stage-country">{stage.track.Country}</div>
                                                    <div className="stage-country">{stage.laps} laps {stage.sprint ? "+ Sprint" : ""}</div>
                                                    <div className="gp-name">{stage.track.title}</div>
                                                    <div className="track-name">{stage.track.CircuitName}</div>
                                                    <img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.TrackImage.url}`} alt=""
                                                         className="track-image"/>
                                                </fieldset>
                                            </Link>
                                        </div>
                                    )
                                }
                                if(!matches) {

                                    return (
                                        <div className="thumbnail-button-block" id='next-gp-calendar' key={index}>
                                            <Link className="stage-link" to={`/stage/${stage.stage.stage}`}>
                                                <fieldset className="schedule-block-next-gp">
                                                    <legend
                                                        className="stage-title">{stage.test ? 'Preseason Tests' : `Stage ${stage.stage.seasonstage}`}</legend>

                                                    <div className="left-block">
                                                        <div className="stage-date">
                                                            <div className="text">{date.format('DD MMM')}</div>
                                                            <img
                                                                src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.logo.url}`}
                                                                alt=""
                                                                className="img"/>
                                                        </div>
                                                        <div className="stage-country">{stage.track.Country}</div>
                                                        <div className="stage-country">{stage.laps} laps {stage.sprint ? "+ Sprint" : ""}</div>
                                                        <div className="gp-name">{stage.track.title}</div>
                                                        <div className="track-name">{stage.track.CircuitName}</div>
                                                        <div className="track-block"><img
                                                            src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.TrackImage.url}`}
                                                            alt=""
                                                            className="track-image"/></div>
                                                    </div>
                                                    <div className="right-block"><img
                                                        src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.trackphoto ? stage.track.trackphoto.url : ''}`}
                                                        alt=""
                                                        className="gp-bg"/>
                                                        <div className="next-gp-time-left">
                                                            <div className="next-gp-left">
                                                                <div className="next-gp-title">GRAND PRIX WEEKEND</div>
                                                                <div className="next-gp-timeleft">
                                                                    <div className="day">--
                                                                        <div className="under-time">DAYS</div>
                                                                    </div>
                                                                    <div className="dots">:</div>
                                                                    <div className="hour">--
                                                                        <div className="under-time">HRS</div>
                                                                    </div>
                                                                    <div className="dots">:</div>
                                                                    <div className="minutes">--
                                                                        <div className="under-time">MINS</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="next-gp-right"><img
                                                                src={`${process.env.REACT_APP_ADMIN_URL}/uploads/LOGOUA_a75979011e.png`}
                                                                alt=""
                                                                className="league-logo"/></div>

                                                        </div>

                                                        <div className="schedule-list-time">
                                                            <div className="stage-time"
                                                                 style={{display: (stage.stage.time_p ? (stage.stage.time_p === '00:00' ? '' : stage.stage.time_p) : 'none')}}>
                                                                <div className="title">Practice:</div>
                                                                <div className="day">{date.format('ddd')}</div>
                                                                <div
                                                                    className="time">{stage.stage.time_p ? stage.stage.time_p.slice(0, 5) : '--:--'}</div>
                                                            </div>
                                                            <div className="stage-time">
                                                                <div className="title">Qualification:</div>
                                                                <div className="day">{date.format('ddd')}</div>
                                                                <div
                                                                    className="time">{stage.stage.time_q.slice(0, 5)}</div>
                                                            </div>
                                                            <div className="stage-time">
                                                                <div className="title">Race:</div>
                                                                <div className="day">{date.format('ddd')}</div>
                                                                <div
                                                                    className="time">{stage.stage.time_r.slice(0, 5)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </Link>
                                        </div>
                                    )
                                }
                            }
                            if(!stage.stage.next){
                                return(
                                    <div className="thumbnail-button" key={index}>
                                        <Link className="stage-link" to={`/stage/${stage.stage.stage}`}>
                                            <fieldset className="schedule-block">
                                                <legend className="stage-title">{stage.test ? 'Preseason Tests' : `Stage ${stage.stage.seasonstage}`}</legend>
                                                <div className="stage-date">
                                                    <div className="text">{date.format('DD MMM') }</div>
                                                    <img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.logo.url}`} alt=""
                                                         className="img"/>
                                                </div>
                                                <div className="stage-country">{stage.track.Country}</div>
                                                <div className="stage-country">{stage.laps} laps {stage.sprint ? "+ Sprint" : ""}</div>
                                                <div className="gp-name">{stage.track.title}</div>
                                                <div className="track-name">{stage.track.CircuitName}</div>
                                                <img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.TrackImage.url}`} alt=""
                                                     className="track-image"/>
                                            </fieldset>
                                        </Link>
                                    </div>
                                )
                            }

                        }


                    })}



                </div>
                </div>
            </div>
        )
    }
}
export default Calendar;