import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import {Routes, Route, useParams} from 'react-router-dom';
//import './Content.scss';
import './Content2.scss';
//import './Web.scss';
import './MainTableAvatars.scss';
import {Link} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";

import moment from 'moment';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from '@mui/material/useMediaQuery';
import GetAvatar from "./GetAvatar";
import PilotStanding2 from "./PilotStanding 2";
import StandingTable from "./StandingTable";
import CalendarOnMain from "./CalendarOnMain";
import TopMenuNew from "./TopMenuNew";
import TopMenuSeasons from './TopMenuSeasons'
import PilotsForSeasonOnMain from "./PilotsForSeasonOnMain";
import _ from "lodash";
import TopMenuNewMobile from "./TopMenuNewMobile";
import StandingTableMobile from "./StandingTableMobile";
import PilotsForSeasonOnMainMobile from "./PilotsForSeasonOnMainMobile";

const SeasonNew = () => {
    const {seasons, getSeason, allResults, getAllRes, getNextStageId, nextGp, seasonsLoaded} = useContext(FormulaContext);
    const [activeClass, setActiveClass] = useState(2);
    const [isResults, setIsResults] = useState (false);
    const matches = useMediaQuery('(max-width:450px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');
    const matches800 = useMediaQuery('(max-width:850px)');
    let params = useParams();

    //let seasonNumber = params.IdSeason;
    //console.log(seasonNumber)
    useEffect(() => {
        getSeason();
        getAllRes();

    }, []);
    useEffect(() => {
        getNextStageId();
    }, []);

    useEffect(() => {
        let seasonID = parseInt(params.IdSeason);

        console.log(allResults)
        for (let i = 0; i < allResults.length; i++){
            console.log(allResults[i].season+' - '+seasonID)
            let r = parseInt(allResults[i].season);

            if(r === seasonID) {

              const resultFinded  =  _.find(allResults, {season: seasonID});
                if(resultFinded.resultlist.length > 0) {
                    setIsResults(true);
                    //console.log('BREAK')
                    break;
                }
            }
            if(r !== seasonID) {

                //console.log('!=')

            }
            console.log(i === allResults.length-1)
            if (i === allResults.length-1){
                setIsResults(false);
               // console.log('last')
            }
            //console.log(allResults[i])

        }
    }, [allResults, params.IdSeason]);


    const SeasonHover = (id) => {
        setActiveClass(id);
    }
    const SeasonOut = () => {

    }




    if (seasonsLoaded) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )

    }
    if (!seasonsLoaded) {

        // if (!params.IdSeason){
        //     //console.log(params.IdSeason = seasons[seasons.length - 1].id);
        // }

        // allResults.forEach((stageResult)=>{
        //     if(isResults){
        //         if(stageResult.season == params.IdSeason){
        //             setIsResults(true);
        //         }
        //     }
        // })



        console.log(isResults)
        if(matches800) {
            return (
                <div className="contentmobile">
                    <div className="contentmobile-container">

                        <div className="contentmobile-title-text">PTP League Racing <br/>{params.IdSeason} Season</div>

                        <TopMenuNewMobile season={params.IdSeason} />
                        <TopMenuSeasons  seasons={seasons} season={params.IdSeason} />

                        {isResults ? <StandingTableMobile idSeason={params.IdSeason}/> : <PilotsForSeasonOnMainMobile idSeason={params.IdSeason} limit={10} />}

                        <div className="contentmobile-title-text">Calendar for {params.IdSeason} Season</div>
                        <CalendarOnMain seasonNumber={params.IdSeason} />




                    </div>

                </div>


            )
        }
        return (
            <div className="content">
                <div className="content-container">

                    <div className="content-title-text">PTP League Racing {
                        `${seasons[seasons.length - 1].season}`
                    }</div>

                    <TopMenuNew season={params.IdSeason} />
                    <TopMenuSeasons seasons={seasons} season={params.IdSeason} />
                         {/*<PilotStanding2 />*/}
                    {isResults ? <StandingTable idSeason={params.IdSeason}/> : <PilotsForSeasonOnMain idSeason={params.IdSeason} />}
                    <div className="content-title-text">Calendar for {params.IdSeason}</div>
                    <CalendarOnMain seasonNumber={params.IdSeason} />



                </div>

            </div>
        )
    }
}
export default SeasonNew;