import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { F1ContextProvider } from "./Context/F1Context";


// const root = ReactDOM.createRoot(document.getElementById('root'));



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


root.render(
  <React.StrictMode>
      <F1ContextProvider>
      <BrowserRouter>
          <App />
      </BrowserRouter>
      </F1ContextProvider>
  </React.StrictMode>
);


