import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';



export default function SpeedDialTooltipOpen({actions, ChooseColumn}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const Choosesomething = (column)=> {
        ChooseColumn(column);
        setOpen(false);
    }

    return (
        <Box sx={{  transform: 'translateZ(0px)', flexGrow: 1 }}>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', top: '-2px', right: '-10px', zIndex: '10'  }}
                icon={<SpeedDialIcon  />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction={'down'}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={()=>{
                            Choosesomething(action.column)
                        }}
                        sx={{ margin: '0', fontSize: '9px', width: '100px', height: '42px', padding: '0'  }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}
