import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

import './Web.scss';





const WebRaceResult = () => {
    let params = useParams();
    const {getlastRace, racestandLoading, racestand, getSeasonRes, seasonResults, seasonResultsLoading} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);



    useEffect(() => {
        getSeasonRes(params.IdSeason);

    }, []);
    useEffect(() => {
        getlastRace();

    }, []);


    console.log(racestand)






    if (racestandLoading || seasonResultsLoading) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }



    const Driver = (p)=>{
        return(
        <div className={`web-first-place web-first-place-background-gradient web-first-place-${racestand[0].driver[p].team.shorttitle} ${p===0 ? 'web-first-place-first' : (p===2 && 'web-first-place-third')}`}>
            <div className="web-team-name-vertical">{racestand[0].driver[p].team.name}</div>
            <div className="web-pilot-place">{p+1}<span className="st">st</span></div>

            <div className="web-pilot-name">{racestand[0].driver[p].pilot.firstname}</div>
            <div className="web-pilot-nickname">{racestand[0].driver[p].pilot.Name}</div>
            <div className="web-pilot-number outline">{racestand[0].driver[p].pilot.number}</div>
            <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${GetAvatar(racestand[0].driver[p].pilot.avatar, params.IdSeason)}`} alt="" className='web-pilot-avatar' /></div>
            <div className="web-name-background"> </div>
        </div>
        )
    }
    return (
        <div className="web-calendar web-calendar-podium">

            <div className="web-calendar-container web-calendar-container-podium">

                {Driver(1)}
                {Driver(0)}
                {Driver(2)}

</div>
        </div>
    )

    /*--------------------------------------------------------------------------------*/

}

export default WebRaceResult;