export const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_SEASONS':
            return { ...state, seasons: action.payload };
        case 'FETCH_STAGES':
            return {...state, stages: action.payload}
        case "FETCH_STAGES_FINISH":
            return {...state, stagesLoading: false}
        case 'FETCH_STAGE':
            return {...state, stage: action.payload}
        case 'FETCH_STAGE_END_LOADING':
            return {...state, stageloading: false}
        case 'FETCH_SEASON_DETAILS':
            return {...state, seasondetails: action.payload}
        case 'FETCH_SEASON_RESULTS_END_LOADING':
            return {...state, seasonResultsLoading: false}
        case 'FETCH_SEASON_RESULTS':
            return {...state, seasonResults: action.payload}
        case 'FETCH_ALL_RESULTS_END_LOADING':
            return {...state, allResultsLoading: false}
        case 'FETCH_ALL_RESULTS':
            return {...state, allResults: action.payload}
        case 'FETCH_NEXT_GP':
            return {...state, nextGp: action.payload}
        case 'FETCH_SEASONS_LOADING_FINISH':
            return {...state, seasonsLoaded: false}
        case 'CLEAR_RESULTS':
            return {...state, seasonResults: [], seasonResultsLoading: true}
        case 'CLEAR_STAGE_RESULTS':
            return {...state, stage: []}
        case 'FETCH_SEASON_PILOTS_END_LOADING':
            return {...state, seasonPilotsLoading: false}
        case 'FETCH_SEASON_PILOTS':
            return {...state, seasonPilots: action.payload}
        case 'FETCH_PILOTS_END_LOADING':
            return {...state, pilotsLoading: false}
        case 'FETCH_PILOTS':
            return {...state, pilots: action.payload}
        case 'FETCH_RACE_STAND':
            return {...state, racestand: action.payload}
        case 'FETCH_RACE_STAND_LOADING':
            return {...state, racestandLoading: false}
        case 'FETCH_RACE_START':
            return {...state, racestart: action.payload}
        case 'FETCH_RACE_START_LOADING':
            return {...state, racestartLoading: false}
        case 'FETCH_EVENT':
            return {...state, stageEvent: action.payload}
    }
};

export const initialFormula = {
    seasons: [],
    seasonsLoaded: true,
    stages: [],
    stagesLoading: true,
    stage: {},
    stageloading: true,
    seasondetails: {},
    seasonResults: [],
    seasonResultsLoading: true,
    nextGp:[],
    seasonPilots: [],
    seasonPilotsLoading: true,
    pilots: [],
    pilotsLoading: true,
    allResults: [],
    allResultsLoading: true,
    racestand: [],
    racestandLoading: true,
    racestart: [],
    racestartLoading: true,
    stageEvent: 'Race',
};
