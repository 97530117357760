import React, {useEffect, useContext} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackgroundImage from "./BackgroundImage";
import Season from "./Season";
import Content from "./Content";
import Calendar from "./Calendar";
import Stage from './Stage';
import WebPilotStanding from "./WebPilotStanding";
import PilotStanding from "./PilotStanding";
import ConstructorStanding from "./ConstructorStanding";
import Pilots from "./Pilots";
import Rating from "./Rating";
import WebRacePodium from "./WebRacePodium";
import WebRaceResult from "./WebRaceRes";
import WebAfterRaceStanding from "./WebAfterRaceStanding";
import WebStartingGrid from "./WebStartingGrid";
import WeatherChoose from "./WeatherChoose";
import  SeasonNew from  "./SeasonNew";


import FormulaContext from "../Context/F1Context";
import useMediaQuery from "@mui/material/useMediaQuery";
import PilotStandingMobile from "./PilotStandingMobile";

const MainPage = ()=>{
    const F1logo = ()=>{
        return(

                <Link to={'/f1'} className="link-logo">
                    <img src="../F1_logo.svg" className="f1-main-logo" alt="logo" />
                </Link>

        )
    }

    const {seasons, getSeason, stageEvent} = useContext(FormulaContext);
    const {pathname} = useLocation();
    const matches800 = useMediaQuery('(max-width:850px)');
    useEffect(() => {
        getSeason();
    }, []);
   // console.log(pathname)
    return(
        <div className='f1-container' >
            <BackgroundImage />
            <div className={`first-open-page-backdrop ${pathname.startsWith('/web') ? 'web-backdrop' : 'web-backdrop'}`}></div>
            <div className={`F1App ${pathname.startsWith('/web') ? 'web-F1App' : stageEvent==='Grid' ? 'web-F1App' : ''}`}>
        <Routes>
            <Route path="/" element={<F1logo />} />
            <Route path="/f1" element={<Content />} />

            <Route path="/season/:IdSeason" element={<SeasonNew />} />
            {/*<Route path="/season/" element={<Content />} />*/}
            <Route path="/pilots/:IdSeason" element={<Pilots />} />
            <Route path="/calendar/:IdSeason" element={<Calendar />} />
            {/*<Route path="/pilotstanding/:IdSeason" element={matches800 ? <PilotStandingMobile /> : <PilotStanding />} />*/}
            <Route path="/pilotstanding/:IdSeason" element={<PilotStanding />} />
            <Route path="/webpilotstanding/:IdSeason" element={<WebPilotStanding />} />
            <Route path="/webracepodium/:IdSeason" element={<WebRacePodium />} />
            <Route path="/webraceres/:IdSeason" element={<WebRaceResult />} />
            <Route path="/webafterracestanding/:IdSeason" element={<WebAfterRaceStanding />} />
            <Route path="/webstartgrid/:IdSeason" element={<WebStartingGrid />} />
            <Route path="/weatherchoose/" element={<WeatherChoose />} />
            <Route path="/stage/:IdStage" element={<Stage />} />
            <Route path="/constructorstanding/:IdSeason" element={<ConstructorStanding />} />
            <Route path="/rating" element={<Rating />} />
            {/*</Route>*/}
            {/*<Route path="/about" element={<About />} />*/}
            {/*<Route path="/order" element={<Order />} />*/}
        </Routes>
            </div>

        </div>
    )
}
export default MainPage;