import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

import './Web.scss';
import Points from "./Points";





const WebRaceResult = () => {
    let params = useParams();
    const {getlastRace, racestandLoading, racestand, getSeasonRes, seasonResults, seasonResultsLoading} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);



    // const driverSeasonStanding = useMemo(()=>{
    //
    //     let pilotlist = [];
    //
    //     seasonResults.forEach((stage, index) => {
    //         // console.log(stage);
    //
    //         if(!stage.test || testShow){
    //             stage.resultlist.forEach((pilotRes, index) => {
    //
    //
    //                 if (pilotRes.race.points >= 0) {
    //                     const pilotresults = _.find(pilotlist, {pilot: pilotRes.pilot.pilot.Name});
    //                     if (pilotRes.pilot.pilot.AI && pilotsAI || !pilotRes.pilot.pilot.AI) {
    //                         if (!pilotresults) {
    //
    //                             pilotlist.push({
    //                                 pilot: pilotRes.pilot.pilot.Name,
    //                                 number: pilotRes.pilot.pilot.number,
    //                                 team: pilotRes.pilot.team.name,
    //                                 avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
    //                                 races: 1,
    //                                 team_full: pilotRes.pilot.team,
    //
    //                                 points: pilotRes.race.points
    //                             })
    //                         }
    //                         if (pilotresults) {
    //
    //                             pilotlist.forEach((driver, index) => {
    //                                 let sprintpoints;
    //                                 if (!pilotRes.sprint) {
    //                                     sprintpoints = 0;
    //                                 }
    //                                 if (pilotRes.sprint) {
    //                                     sprintpoints = pilotRes.sprint.points;
    //                                 }
    //                                 if (driver.pilot === pilotresults.pilot) {
    //                                     pilotlist[index] = {
    //                                         pilot: driver.pilot,
    //                                         number: driver.number,
    //                                         avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
    //                                         team: pilotRes.pilot.team.name,
    //                                         races: driver.races + 1,
    //                                         team_full: pilotRes.pilot.team,
    //
    //                                         points: driver.points + pilotRes.race.points + sprintpoints
    //                                     }
    //                                 }
    //                             })
    //                         }
    //                     }
    //                 }
    //
    //             })
    //         }
    //
    //     })
    //     const pointslist = _.sortBy(pilotlist, [function (o) {
    //         return o.points;
    //     }]);
    //
    //     const driverStandingList = _.orderBy(pointslist, ['points'], ['desc']);
    //     return driverStandingList;
    // },[seasonResults]);

    useEffect(() => {
        getSeasonRes(params.IdSeason);

    }, []);
    useEffect(() => {
        getlastRace();

    }, []);












    if (racestandLoading || seasonResultsLoading) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }

    console.log(racestand[0].driver[0])
    let pilotfl = {};
    return (
        <div className="web-calendar">

            <div className="web-calendar-container">

                <div className={`web-first-place web-first-place-${racestand[0].driver[0].team.shorttitle}`}>
                    <div className="web-team-name-vertical">{racestand[0].driver[0].team.name}</div>
                    <div className="web-pilot-place">1st</div>
                    <div className="web-pilot-number outline">{racestand[0].driver[0].pilot.number}</div>
                    <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${GetAvatar(racestand[0].driver[0].pilot.avatar, params.IdSeason)}`} alt="" className='web-pilot-avatar' /></div>


                </div>
                <div className='web-table-list'>
                    <div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                    <div className="web-league-under-title">Race Results <span className="web-season"> Season {params.IdSeason}</span></div>
                    <div className="web-league-list">

                        <div className={`web-pilot-row`}>
                            <div></div>
                            <div className="web-name"></div>
                            <div className="web-team-logo"></div>
                            <div className="web-team"></div>
                            <div className="web-races">{/*Races*/}</div>
                            <div className="web-points">Points</div>

                        </div>

                        {racestand[0].driver.map((pilot, index) => {

                            if(pilot.fl){
                                pilotfl = pilot;
                            }


                            return (
                                <div className={`web-pilot-row  ${index === pilotOnSide  ? 'first-row' : ''}`} key={index}>
                                    <div className="web-place">{index + 1}</div>
                                    <div className="web-name"> <span className="fn"> {pilot.pilot.firstname} </span> <span className="n"> {pilot.pilot.Name}</span></div>
                                    <div className={`web-team-logo web-team-logo-${pilot.team.shorttitle}`}><img className="web-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`} alt="" /></div>
                                    <div className={`web-team web-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>
                                    <div className="web-races">{/*pilot.races*/}</div>
                                    <div className="web-points">{Points(Number(index+1),pilot.fl ? 1 : 0), params.IdSeason, 0}</div>

                                </div>
                            )

                        })}
                        <div className="web-row-fl">
                            <span className="fl">Fastest lap</span>
                            <span className="name">{pilotfl.pilot.firstname} {pilotfl.pilot.Name}</span>
                            <span>{pilotfl.team.name} </span>
                            <span>{pilotfl ? pilotfl.timelap : ''}</span>
                            <span>+1 point</span>  </div>
                    </div>

                </div>
</div>
        </div>
    )

    /*--------------------------------------------------------------------------------*/

}

export default WebRaceResult;