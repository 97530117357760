import React, {useContext, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";
import './Calendar.scss';
import './Pilots.scss';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopMenu from "./TopMenu";
import GetAvatar from "./GetAvatar";
import DetailPilot from "./DetailsPilot";

const Pilots = () => {
    let params = useParams();

    const {getStages, stages, stagesLoading,seasonPilotsLoading, seasondetails,getSeasonPilots,seasonPilots, getAllRes, allResults, allResultsLoading, dispatch} = useContext(FormulaContext);
    const matches = useMediaQuery('(max-width:450px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');


    useEffect(() => {
        getSeasonPilots(params.IdSeason);
        getAllRes();
        // return function cleanup() {
        //     dispatch({type: 'CLEAR_RESULTS'});
        // };
    }, []);
    let teams = [];
    let reserveTeam = [];
    if (seasonPilotsLoading) return (<div className="calendar">
        <div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>
        </div>
        <div className="thumbnail-list"><CircularProgress color="inherit"/></div>
    </div>)




    if (seasonPilotsLoading === false) {
        //console.log(allResults)


        const DP = (pilot, results, season)=>{
            let pilotDetails = {
                name: '',
                races: 0,
                wins: 0,
                polePosition: 0,
                points: 0,
                podiums: 0,
                dnf: 0,
                highestPosition: 20,
                highestPositionCount: 1,
                fl: 0,
                tenFinish:  0,
                penalties: 0,
            };


            results.forEach((race, index)=>{
                let seasonWins = 0;


                //const ResSeason = race.find(stage => stage.season == season);

                const pilotRes = race.resultlist.find(pilotresult => pilotresult.pilot.pilot.id === pilot.id);
                //console.log(race)


                if(race.season != 2 && race.season != 3){
                    //console.log(race.season);
                    if (pilotRes) {
                        //console.log(pilot.id, pilotRes)

                        pilotDetails = {
                            name: pilot.Name,
                            races: pilotDetails.races + 1,
                            wins: pilotRes.race.finish == 1 ? pilotDetails.wins + 1 : pilotDetails.wins,
                            polePosition: pilotRes.qualify ? (pilotRes.qualify.finish == 1 ? pilotDetails.polePosition + 1 : pilotDetails.polePosition) : pilotDetails.polePosition,
                            points: pilotDetails.points + pilotRes.race.points,
                            podiums: pilotRes.race.finish < 4 ? pilotDetails.podiums + 1 : pilotDetails.podiums,
                            dnf: pilotRes.race.dnf ? pilotDetails.dnf + 1 : pilotDetails.dnf,
                            highestPositionCount: pilotRes.race.finish < pilotDetails.highestPosition ? 1 : (pilotRes.race.finish == pilotDetails.highestPosition ? pilotDetails.highestPositionCount + 1 : pilotDetails.highestPositionCount),
                            highestPosition: pilotRes.race.finish < pilotDetails.highestPosition ? pilotRes.race.finish : pilotDetails.highestPosition,
                            fl: pilotRes.race.fastestlap ? pilotDetails.fl + 1 : pilotDetails.fl,
                            tenFinish:  pilotRes.race.finish < 11 ? pilotDetails.tenFinish + 1 : pilotDetails.tenFinish,
                            penalties: pilotRes.race.penalties != "-" ? pilotDetails.penalties +1 : pilotDetails.penalties,

                        }

                        //console.log('all', pilotDetails)
                    }
                }

            })

            // console.log('season', pilotSeasonDetails)
            // console.log('all', pilotDetails)
            return {all: pilotDetails}
        }



        seasonPilots.forEach((pilot, index) => {

            if(pilot.team.name === 'Personal Car'){

                if(pilot.reserve){
                    return  reserveTeam.push({
                        pilot1: pilot.pilot,
                        pilot2: {},
                        teamName: pilot.team.name,
                        team: pilot.team,
                        avatar1: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                        avatar2: '',
                        detailPilot1: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                        detailPilot2: {},
                        detailRating1: DP(pilot.pilot, allResults, params.IdSeason),
                        detailRating2: {},
                        reserve1: pilot.reserve,
                        reserve2: false
                    })

                }

                DetailPilot(pilot.pilot, allResults, params.IdSeason)
                teams.push({
                    pilot1: pilot.pilot,
                    pilot2: {},
                    teamName: pilot.team.name,
                    team: pilot.team,
                    avatar1: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                    avatar2: '',
                    detailPilot1: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                    detailPilot2: {},
                    detailRating1: DP(pilot.pilot, allResults, params.IdSeason),
                    detailRating2: {},
                })
            }


            if(pilot.team.name != 'Personal Car'){
                let teamsList = _.find(teams, {teamName: pilot.team.name});

                if (!teamsList) {

                    if(pilot.reserve){
                        return  reserveTeam.push({
                            pilot1: pilot.pilot,
                            pilot2: {},
                            teamName: pilot.team.name,
                            team: pilot.team,
                            avatar1: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                            avatar2: '',
                            detailPilot1: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                            detailPilot2: {},
                            detailRating1: DP(pilot.pilot, allResults, params.IdSeason),
                            detailRating2: {},
                            reserve1: pilot.pilot.reserve,
                            reserve2: false
                        })

                    }

                    //console.log(DetailPilot(pilot.pilot, allResults, params.IdSeason))
                    teams.push({
                        pilot1: pilot.pilot,
                        pilot2: {},
                        teamName: pilot.team.name,
                        team: pilot.team,
                        avatar1: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                        avatar2: '',
                        detailPilot1: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                        detailPilot2: {},
                        detailRating1: DP(pilot.pilot, allResults, params.IdSeason),
                        detailRating2: {},
                        reserve1: pilot.reserve,
                        reserve2: false
                    })
                }

                if (teamsList) {
                    if(pilot.reserve){
                        return  reserveTeam.forEach((team, index) => {
                            if (team.teamName === teamsList.teamName) {
                                teams[index] = {
                                    ...team,
                                    pilot2: pilot.pilot,
                                    avatar2: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                                    detailPilot2: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                                    detailRating2: DP(pilot.pilot, allResults, params.IdSeason),
                                    reserve2: pilot.reserve
                                }
                            }
                        })

                    }

                    DetailPilot(pilot.pilot, allResults, params.IdSeason)
                    teams.forEach((team, index) => {
                        if (team.teamName === teamsList.teamName) {
                            teams[index] = {
                                ...team,
                                pilot2: pilot.pilot,
                                avatar2: GetAvatar(pilot.pilot.avatar, params.IdSeason),
                                detailPilot2: DetailPilot(pilot.pilot, allResults, params.IdSeason),
                                detailRating2: DP(pilot.pilot, allResults, params.IdSeason),
                                reserve2: pilot.reserve
                            }
                        }
                    })

                }
            }
            //console.log(teams);


        })
        const RatingCounting = (pd) => {
            let rateLvl = 0;
            rateLvl = ((pd.races - pd.dnf) * 0.1) + (pd.wins * 0.5) + (pd.podiums * 0.1) + (pd.polePosition * 0.1) + (pd.fl * 0.05) - (pd.dnf * 0.01) + (pd.tenFinish * 0.01) - (pd.penalties * 0.001);
            // console.log(pd.name,pd.races, pd.dnf, ((pd.races - pd.dnf) * 0.1), (pd.wins * 0.5), (pd.podiums * 0.1), (pd.polePosition * 0.1), (pd.fl * 0.05), (pd.dnf * 0.01))
            return rateLvl.toFixed(3);
        }

        const FirstPilot = (team)=>{
            return(
                <div className="first-pilot">
                    <img src={`${process.env.REACT_APP_ADMIN_URL}${team.avatar1}`} alt=""
                         className="avatar"/>
                    <div className="pilot-info">
                    <div className="name">{team.pilot1.Name}</div>

                    <div style={{fontSize: '9px'}}><b>{ RatingCounting(team.detailRating1.all)} - Rating</b>, {team.pilot1.system},   <a href={`${team.pilot1.steam}`}>Steam</a></div>
                    {/*<div>{pilot1.info}</div>*/}
                        <div className="season">All seasons / Season {params.IdSeason}</div>
                        <div>{team.detailPilot1.all.races} / {team.detailPilot1.season.races} - Races</div>
                        <div>{team.detailPilot1.all.wins} / {team.detailPilot1.season.wins} - Wins</div>
                        <div>{team.detailPilot1.all.polePosition} / {team.detailPilot1.season.polePosition} - Poles</div>
                        <div>{team.detailPilot1.all.podiums} / {team.detailPilot1.season.podiums} - Podiums</div>
                        <div>{team.detailPilot1.all.fl} / {team.detailPilot1.season.fl} - Fastest Lap</div>
                        <div>{team.detailPilot1.all.highestPosition} ({team.detailPilot1.all.highestPositionCount}) / {team.detailPilot1.season.highestPosition} ({team.detailPilot1.season.highestPositionCount}) - Highest Position (count)</div>
                        <div>{team.detailPilot1.all.points} / {team.detailPilot1.season.points} - Points</div>
                        <div>{team.detailPilot1.all.dnf} / {team.detailPilot1.season.dnf} - DNF</div>
                    </div>
                </div>
            )
        }
        const SecondPilot = (team)=>{
            return(
                <div className="second-pilot">
                    <div className="pilot-info">

                        <div className="name">{team.pilot2.Name}</div>

                        <div style={{fontSize: '9px'}}><a href={`${team.pilot2.steam}`}>Steam</a>, {team.pilot2.system}, <b>Rating - { RatingCounting(team.detailRating2.all)}</b></div>
                        {/*<div>{pilot2.info}</div>*/}
                        <div className="season">All seasons / Season {params.IdSeason}</div>
                        <div>Races - {team.detailPilot2.all.races} / {team.detailPilot2.season.races}</div>
                        <div>Wins - {team.detailPilot2.all.wins} / {team.detailPilot2.season.wins}</div>
                        <div>Poles - {team.detailPilot2.all.polePosition} / {team.detailPilot2.season.polePosition}</div>
                        <div>Podiums - {team.detailPilot2.all.podiums} / {team.detailPilot2.season.podiums}</div>
                        <div>Fastest Lap - {team.detailPilot2.all.fl} / {team.detailPilot2.season.fl}</div>
                        <div>Highest Position (count) - {team.detailPilot2.all.highestPosition} ({team.detailPilot2.all.highestPositionCount}) / {team.detailPilot2.season.highestPosition} ({team.detailPilot2.season.highestPositionCount})</div>
                        <div>Points - {team.detailPilot2.all.points} / {team.detailPilot2.season.points}</div>
                        <div>DNF - {team.detailPilot2.all.dnf} / {team.detailPilot2.season.dnf}</div>
                    </div>
                    <img src={`${process.env.REACT_APP_ADMIN_URL}${team.avatar2}`} alt=""
                         className="avatar"/>

                </div>
            )
        }

        return (
            <div className="calendar" id="pilots-block">
                <TopMenu season={params.IdSeason} />
                {/*<div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>*/}
                {/*</div>*/}
                <div className="thumbnail-list">


                    {teams.map((team, index) => {
                        //{console.log(team)}
                                return(

                                    <div className="team-pilots" key={index}  style={{background: `url(${process.env.REACT_APP_ADMIN_URL}${matches ? team.team.Car.formats.thumbnail.url : team.team.Car.formats.small.url})  no-repeat center bottom`}}>
                                        <fieldset className="team-block" >
                                            <legend className="team-title">{team.team.name}</legend>

                                            {team.pilot1.Name ? FirstPilot(team) : ''}
                                            {team.pilot2.Name ? SecondPilot(team) : ''}

                                            </fieldset>
                                    </div>
                                )



                    })}

                        <legend className="team-title">Reserve Pilots</legend>



                    {reserveTeam.map((team, index) => {
                        //{console.log(team)}
                        return(

                            <div className="team-pilots" key={index}  style={{background: `url(${process.env.REACT_APP_ADMIN_URL}${matches ? team.team.Car.formats.thumbnail.url : team.team.Car.formats.small.url})  no-repeat center bottom`}}>
                                <fieldset className="team-block" >
                                    <legend className="team-title">{team.team.name}</legend>

                                    {team.pilot1.Name ? FirstPilot(team) : ''}
                                    {team.pilot2.Name ? SecondPilot(team) : ''}

                                </fieldset>
                            </div>
                        )



                    })}


                </div>
            </div>
        )
    }
}
export default Pilots;