import React, {useContext, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";
import './Calendar.scss';
import './Pilots.scss';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopMenu from "./TopMenu";
import GetAvatar from "./GetAvatar";
//import DetailPilot from "./DetailsPilot";

const Rating = () => {
    let params = useParams();

    const {pilotsLoading, seasondetails,getPilots, pilots, getAllRes, allResults, allResultsLoading, dispatch} = useContext(FormulaContext);
    const matches = useMediaQuery('(max-width:450px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');


    useEffect(() => {
        getPilots();
        getAllRes();
        // return function cleanup() {
        //     dispatch({type: 'CLEAR_RESULTS'});
        // };
    }, []);
    let ratingList = [];

    if (pilotsLoading) return (<div className="calendar">
        <div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>
        </div>
        <div className="thumbnail-list"><CircularProgress color="inherit"/></div>
    </div>)




    if (pilotsLoading === false && allResultsLoading === false) {
        //console.log(allResults)


        const DP = (pilot, results, season)=>{
            let pilotDetails = {
                name: '',
                races: 0,
                wins: 0,
                polePosition: 0,
                points: 0,
                podiums: 0,
                dnf: 0,
                highestPosition: 20,
                highestPositionCount: 1,
                fl: 0,
                tenFinish: 0,
                penalties: 0,
            };


            results.forEach((race, index)=>{
                let seasonWins = 0;


                //const ResSeason = race.find(stage => stage.season == season);

                const pilotRes = race.resultlist.find(pilotresult => pilotresult.pilot.pilot.id === pilot.id);
                //console.log(race)


                if(race.season != 2 && race.season != 3){
                    //console.log(race.season);
                    if (pilotRes) {
                        //console.log(pilot.id, pilotRes)

                        pilotDetails = {
                            name: pilot.Name,
                            races: pilotDetails.races + 1,
                            wins: pilotRes.race.finish == 1 ? pilotDetails.wins + 1 : pilotDetails.wins,
                            polePosition: pilotRes.qualify ? (pilotRes.qualify.finish == 1 ? pilotDetails.polePosition + 1 : pilotDetails.polePosition) : pilotDetails.polePosition,
                            points: pilotDetails.points + pilotRes.race.points,
                            podiums: pilotRes.race.finish < 4 ? pilotDetails.podiums + 1 : pilotDetails.podiums,
                            dnf: pilotRes.race.dnf ? pilotDetails.dnf + 1 : pilotDetails.dnf,
                            highestPositionCount: pilotRes.race.finish < pilotDetails.highestPosition ? 1 : (pilotRes.race.finish == pilotDetails.highestPosition ? pilotDetails.highestPositionCount + 1 : pilotDetails.highestPositionCount),
                            highestPosition: pilotRes.race.finish < pilotDetails.highestPosition ? pilotRes.race.finish : pilotDetails.highestPosition,
                            fl: pilotRes.race.fastestlap ? pilotDetails.fl + 1 : pilotDetails.fl,
                            tenFinish:  pilotRes.race.finish < 11 ? pilotDetails.tenFinish + 1 : pilotDetails.tenFinish,
                            penalties: pilotRes.race.penalties != "-" ? pilotDetails.penalties +1 : pilotDetails.penalties,

                        }

                        //console.log('all', pilotDetails)
                    }
                }

            })

            // console.log('season', pilotSeasonDetails)
            // console.log('all', pilotDetails)
            return {all: pilotDetails}
        }










        pilots.map((pilot, index) => {

            const RatingCounting = (pd) => {
                let rateLvl = 0;
                rateLvl = ((pd.races - pd.dnf) * 0.1) + (pd.wins * 0.5) + (pd.podiums * 0.1) + (pd.polePosition * 0.1) + (pd.fl * 0.05) - (pd.dnf * 0.01) + (pd.tenFinish * 0.01) - (pd.penalties * 0.001);
               // console.log(pd.name,pd.races, pd.dnf, ((pd.races - pd.dnf) * 0.1), (pd.wins * 0.5), (pd.podiums * 0.1), (pd.polePosition * 0.1), (pd.fl * 0.05), (pd.dnf * 0.01))
                return rateLvl.toFixed(3);
            }


            //console.log(pilot)
            if(pilot.AI) return null;
            const pilotDetails = DP(pilot, allResults)
            if(!pilotDetails) return null;
            //console.log(pilotDetails.all.name, RatingCounting(pilotDetails.all).toFixed(2))


const rate = RatingCounting(pilotDetails.all);
            //const rate = (pilotDetails.all.points/pilotDetails.all.races).toFixed(2);
            if(!pilot.active)return null;
            ratingList.push({
                name: pilot.Name,
                rating: Number(rate ? rate : '-')
            })






        })
        //console.log(ratingList)

        const sortedList = _.orderBy(ratingList, ['rating'], ['desc']);
       // console.log(sortedList)
        return (
            <div className="calendar" id="pilots-block">
                <TopMenu season={params.IdSeason} />
                {/*<div className="title-page-background"><Link className="title-page" to={`/season/${params.IdSeason}`}>{seasondetails.season}</Link>*/}
                {/*</div>*/}
                <div style={{textAlign: 'center'}}>


                    {sortedList.map((pilot, index) => {

                        return(

                            <div key={index} >
                                <div className="name" id="name-rating" style={{ }}>{index+1}. {pilot.name} - {pilot.rating != '-' ? pilot.rating : '-'}</div>
                            </div>
                        )



                    })}



                </div>
            </div>
        )
    }
}
export default Rating;