import * as React from 'react';

import {Routes, Route, useParams, useNavigate} from 'react-router-dom';


import './Stage.scss';
import {Link} from 'react-router-dom';

import AlertDialog from "./Dialog";
import {useState, useContext, useEffect} from "react";
import StartingGrid2 from "./StartingGrid2";

import FormulaContext from "../Context/F1Context";
import Points from "./Points";
// import './Calendar.scss';
import _ from 'lodash';
import Tyres from './Tyres';
import TyresStrategy from './TyresStrategy';
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from "@mui/material/useMediaQuery";
import HtmlParser from "./HtmlParser";
import TopMenuNew from "./TopMenuNew";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import SpeedDialTooltipOpen from "./SpeedDial";
import GetAvatar from "./GetAvatar";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TopMenuNewMobile from "./TopMenuNewMobile";
import TopMenuSeasons from "./TopMenuSeasons";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import LocalParkingIcon from '@mui/icons-material/LocalParking';

const Stage = () => {
    const [full, setFull] = useState(true);
    const {stage, getStageRes, stageloading, dispatch} = useContext(FormulaContext);
    const [event, setEvent] = useState('Race');
    const [column, setColumn] = useState('points')
    const [stratDetails, setStratDetails] = useState(false);
    const matches800 = useMediaQuery('(max-width:850px)');


    const actions = {
        practice: [
            {icon: <FileCopyIcon/>, name: 'Team', column: 'team'},
            {icon: <SaveIcon/>, name: 'Tyre', column: 'tyre'},
            {icon: <PrintIcon/>, name: 'Laps', column: 'laps'},
            {icon: <ShareIcon/>, name: 'Lap Time', column: 'lapTime'},
            {icon: <ShareIcon/>, name: 'Gap', column: 'gap'},
        ],
            race: [
                {icon: <FileCopyIcon/>, name: 'Team', column: 'team'},
                {icon: <SaveIcon/>, name: 'Start Position', column: 'start'},
                {icon: <PrintIcon/>, name: 'Strategy', column: 'strategy'},
                {icon: <ShareIcon/>, name: 'Pitstops', column: 'pitstops'},
                {icon: <ShareIcon/>, name: 'Lap Time', column: 'lapTime'},
                {icon: <PrintIcon/>, name: 'Time', column: 'time'},
                {icon: <ShareIcon/>, name: 'Penalties', column: 'pen'},
                {icon: <ShareIcon/>, name: 'Points', column: 'points'},
            ],
            sprint: [
                {icon: <FileCopyIcon/>, name: 'Team', column: 'team'},
                {icon: <SaveIcon/>, name: 'Start Position', column: 'start'},
                {icon: <ShareIcon/>, name: 'Lap Time', column: 'lapTime'},
                {icon: <PrintIcon/>, name: 'Time', column: 'time'},
                {icon: <ShareIcon/>, name: 'Points', column: 'points'},
            ],
            qualify: [
                {icon: <FileCopyIcon/>, name: 'Team', column: 'team'},
                {icon: <SaveIcon/>, name: 'Tyres', column: 'tyre'},
                {icon: <ShareIcon/>, name: 'Q1', column: 'q1'},
                {icon: <PrintIcon/>, name: 'Q2', column: 'q2'},
                {icon: <ShareIcon/>, name: 'Q3', column: 'q3'},
                {icon: <ShareIcon/>, name: 'Penalties', column: 'pen'},
            ],
    }
    ;


    const ChooseColumn = (newColumn)=>{
        console.log(newColumn)
        setColumn(newColumn);

    }


    let params = useParams();
    let navigate = useNavigate();

    const matches = useMediaQuery('(max-width:500px)');

    const OpenFull = () => {
        setFull(!full);
    }

    useEffect(() => {
        console.log(params.IdStage);
        getStageRes(params.IdStage);

    }, []);

    const TableListRow = (position, pilot, team, startingGrid, pitstops, laptime, timednf, penalties, points, odd) => {

        if (matches) {
            if(event === 'Practice') {
                return (<div className={`table-list-row ${odd} mobile-4-columns`}>
                    <div className="position">{position}</div>
                    <div className="pilot">{pilot}</div>
                    <div><SpeedDialTooltipOpen actions={actions.practice} event={'practice'}
                                               ChooseColumn={ChooseColumn}/></div>
                    {column === 'team' ? <div className="team">{team}</div> : ''}
                    {column === 'tyre' ? <div className="starting-grid">{startingGrid}</div> : ''}
                    {/*{event === 'Race' ? <div className="points">Start Tyre</div> : ''}*/}
                    {/*{event === 'Race' ? <div className="points">Strategy - {stage[0].laps} Laps</div> : ''}*/}
                    {column === 'laps' ? <div className="pitstops">{pitstops}</div> : ''}
                    {column === 'lapTime' ? <div className="lap-time">{laptime}</div> : ''}
                    {column === 'gap' ? <div className="time-dnf">{timednf}</div> : ''}

                </div>)
            }
            if(event === 'Race') {
                return (<div className={`table-list-row ${odd} mobile-4-columns`}>
                    <div className="position">{position}</div>
                    <div className="pilot">{pilot}</div>
                    <div><SpeedDialTooltipOpen actions={actions.race} event={'race'}
                                               ChooseColumn={ChooseColumn}/></div>
                    {column === 'team' ? <div className="team">{team}</div> : ''}
                    {column === 'start' ? <div className="starting-grid">{startingGrid}</div> : ''}
                    {column === 'strategy' ? <div className="points">Strategy - {stage[0].laps} Laps</div> : ''}
                    {column === 'pitstops' ? <div className="pitstops">{pitstops}</div> : ''}
                    {column === 'lapTime' ? <div className="lap-time">{laptime}</div> : ''}
                    {column === 'time' ? <div className="time-dnf">{timednf}</div> : ''}
                    {column === 'pen' ? <div className="points">{penalties}</div> : ''}
                    {column === 'points' ? <div className="points">{points}</div> : ''}

                </div>)
            }
            if(event === 'Sprint') {
                return (<div className={`table-list-row ${odd} mobile-4-columns`}>
                    <div className="position">{position}</div>
                    <div className="pilot">{pilot}</div>
                    <div><SpeedDialTooltipOpen actions={actions.sprint} event={'sprint'}
                                               ChooseColumn={ChooseColumn}/></div>
                    {column === 'team' ? <div className="team">{team}</div> : ''}
                    {column === 'start' ? <div className="starting-grid">{startingGrid}</div> : ''}
                    {/*{column === 'strategy' ? <div className="points">Strategy - {stage[0].laps} Laps</div> : ''}*/}
                    {/*{column === 'pitstops' ? <div className="pitstops">{pitstops}</div> : ''}*/}
                    {column === 'lapTime' ? <div className="lap-time">{laptime}</div> : ''}
                    {column === 'time' ? <div className="time-dnf">{timednf}</div> : ''}
                    {/*{column === 'pen' ? <div className="points">{penalties}</div> : ''}*/}
                    {column === 'points' ? <div className="points">{points}</div> : ''}

                </div>)
            }
            if(event === 'Qualify') {
                return (<div className={`table-list-row ${odd} mobile-4-columns`}>
                    <div className="position">{position}</div>
                    <div className="pilot">{pilot}</div>
                    <div><SpeedDialTooltipOpen actions={actions.qualify} event={'qualify'}
                                               ChooseColumn={ChooseColumn}/></div>
                    {column === 'team' ? <div className="team">{team}</div> : ''}
                    {column === 'tyre' ? <div className="starting-grid">{startingGrid}</div> : ''}
                    {/*{column === 'strategy' ? <div className="points">Strategy - {stage[0].laps} Laps</div> : ''}*/}
                    {column === 'q1' ? <div className="pitstops">{pitstops}</div> : ''}
                    {column === 'q2' ? <div className="lap-time">{laptime}</div> : ''}
                    {column === 'q3' ? <div className="time-dnf">{timednf}</div> : ''}
                    {/*{column === 'pen' ? <div className="points">{penalties}</div> : ''}*/}
                    {column === 'pen' ? <div className="points">{points}</div> : ''}

                </div>)
            }
        }

        return (

            <div className={`main-stage-row`}>
                <div>{position}</div>
                <div className="main-name">{pilot}</div>
                <div className="main-team-logo"></div>
                <div className="main-team">{team}</div>
                <div className='header-gp-with-buttons'>
                    <div>{startingGrid}</div>
                    <div>{pitstops}</div>
                    <div>{laptime}</div>
                    <div>{penalties}</div>
                </div>
                <div className="main-races">{timednf}</div>
                <div className="main-points">Points</div>

            </div>


        )
    }

    const LinkBack = () => {
        return (matches ? <TopMenuNewMobile season={stage[0].stage.season}/> : <TopMenuNew season={stage[0].stage.season}/>
            // <div className="title-page-background"><Link className="title-page"
            //                                              to={`/calendar/${stage[0].stage.season}`}>CALENDAR</Link>
            // </div>
        )
    }
    let i = 0;
    const ChangeTable = (e) => {
        setEvent(e);
        //setFull(false)
        dispatch({type: 'FETCH_EVENT', payload: e})
        setColumn('team');
        i = 0;
    }

    const TableHeader = () => {
        if (event === 'Race') {

            if(matches800){
                return (

                    <div className={`main-stage-row main-stage-row-mobile`}>
                        <div>#</div>

                        <div className="main-team-logo"></div>
                        <div className="main-team">Driver</div>
                        {/*<div className='header-gp-with-buttons' onClick={()=>{*/}
                        {/*    setStratDetails(!stratDetails);*/}
                        {/*}}>*/}
                        {/*    {stratDetails ? <><div>Start</div><div>Pit</div><div>Lap Time</div><div>Pens</div> <AutorenewIcon className='autorenewIcon' sx={{width: '20px', marginTop: '-5px', padding: '0px'}} /></>: <><div>Strategy</div> <div></div>  <div></div> <AutorenewIcon className='autorenewIcon' sx={{width: '20px', marginTop: '0', marginLeft: '70px', padding: '0px'}} /></>}*/}

                        {/*</div>*/}

                        <div className="main-races">Time</div>
                        <div className="main-points">Points</div>

                    </div>
                )
            }

            return (

                <div className={`main-stage-row`}>
                    <div>#</div>
                    <div className="main-name">Driver</div>
                    <div className="main-team-logo"></div>
                    <div className="main-team">Team</div>
                    <div className='header-gp-with-buttons' onClick={()=>{
                        setStratDetails(!stratDetails);
                    }}>
                        {stratDetails ? <><div>Start</div><div>Pit</div><div>Lap Time</div><div>Pens</div> <AutorenewIcon className='autorenewIcon' sx={{width: '20px', marginTop: '-5px', padding: '0px'}} /></>: <><div>Strategy</div> <div></div>  <div></div> <AutorenewIcon className='autorenewIcon' sx={{width: '20px', marginTop: '0', marginLeft: '70px', padding: '0px'}} /></>}

                    </div>

                    <div className="main-races">Time</div>
                    <div className="main-points">Points</div>

                </div>
            )
        }
        if (event === 'Practice') {
            return (<div
                    className="top">{TableListRow('#', 'Pilot', column === 'team' || !matches ? 'Team' : '', column === 'tyre' || !matches ? "Tyre" : '', column === 'laps' || !matches ? 'Laps' : '', column === 'lapTime' || !matches ? "Lap Time" : '', column === 'gap' || !matches ? 'Gap' : '', column === 'points' || !matches ? 'Points' : '', '')}</div>)
        }
        if (event === 'Sprint') {
            return (<div
                    className="top">{TableListRow('#', 'Pilot', column === 'team' || !matches ? 'Team' : '', column === 'start' || !matches ? "Starting Grid" : '', '', column === 'lapTime' || !matches ? "Lap Time" : '', column === 'time' || !matches ? 'Race Time' : '', column === 'pen' || !matches ? 'Pen' : '', column === 'points' || !matches ? 'Points' : '')}</div>)
        }
        if (event === 'Qualify') {
            return (

                <div className={`main-stage-row`}>
                    <div>#</div>
                    <div className="main-name">Driver</div>
                    <div className="main-team-logo"></div>
                    <div className="main-team">Team</div>
                    <div className='header-gp-with-buttons'>
                    <div>Tyres</div><div></div><div>Q1</div><div>Q2</div><div>Q3</div>

                    </div>

                    <div className="main-races"></div>
                    <div className="main-points">Penalty</div>

                </div>


)
        }
        if (event === 'Grid') {
        }
    }


    const ChooseStage = ()=>{
        if(matches800){
            return (
                <div className="event-choose-buttons">
                    <div onClick={() => {
                        ChangeTable('Practice');

                    }} className={`event-buttons first ${event === "Practice" ? 'active' : ''}`}>
                        {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_2_a152ea1638.jpg`} alt=""*/}
                        {/*     className="event-buttons-image"/>*/}
                        <div className="event-title"><LocalParkingIcon/></div>
                    </div>
                    <div onClick={() => {
                        ChangeTable('Qualify');
                    }} className={`event-buttons ${event === "Qualify" ? 'active' : ''}`}>
                        {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_3_ad315a88a0.jpg`} alt=""*/}
                        {/*     className="event-buttons-image"/>*/}
                        <div className="event-title"><AccessTimeIcon /></div>
                    </div>

                    <div style={{display: (stage[0].resultlist[0].sprint ? 'block' : 'none')}} onClick={() => {
                        ChangeTable('Sprint');
                    }} className={`event-buttons ${event === "Sprint" ? 'active' : ''}`}>
                        {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_3_ad315a88a0.jpg`} alt=""*/}
                        {/*     className="event-buttons-image"/>*/}
                        <div className="event-title">Sprint</div>
                    </div>
                    <div onClick={() => {
                        ChangeTable('Grid');
                    }} className={`event-buttons ${event === "Grid" ? 'active' : ''}`}>
                        {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_4_d692e0c4cf.jpg`} alt=""*/}
                        {/*     className="event-buttons-image"/>*/}
                        <div className="event-title"><ViewTimelineIcon/></div>
                    </div>
                    <div onClick={() => {
                        ChangeTable('Race');
                    }} className={`event-buttons ${event === "Race" ? 'active' : ''}`}>
                        {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_5_967d944162.jpg`} alt=""*/}
                        {/*     className="event-buttons-image"/>*/}
                        <div className="event-title"><SportsScoreIcon /></div>
                    </div>
                    {/*<div onClick={() => {*/}
                    {/*    ChangeTable('Replays');*/}
                    {/*}} className={`event-buttons lust ${event === "Replays" ? 'active' : ''}`}>*/}
                    {/*    /!*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_6_c20d295fee.jpg`} alt=""*!/*/}
                    {/*    /!*     className="event-buttons-image"/>*!/*/}
                    {/*    <div className="event-title">Replays</div>*/}
                    {/*</div>*/}

                </div>
            )
        }


        return (
            <div className="event-choose-buttons">
                <div onClick={() => {
                    ChangeTable('Practice');

                }} className={`event-buttons first ${event === "Practice" ? 'active' : ''}`}>
                    {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_2_a152ea1638.jpg`} alt=""*/}
                    {/*     className="event-buttons-image"/>*/}
                    <div className="event-title">Practice</div>
                </div>
                <div onClick={() => {
                    ChangeTable('Qualify');
                }} className={`event-buttons ${event === "Qualify" ? 'active' : ''}`}>
                    {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_3_ad315a88a0.jpg`} alt=""*/}
                    {/*     className="event-buttons-image"/>*/}
                    <div className="event-title">Qualifying</div>
                </div>

                <div style={{display: (stage[0].resultlist[0].sprint ? 'block' : 'none')}} onClick={() => {
                    ChangeTable('Sprint');
                }} className={`event-buttons ${event === "Sprint" ? 'active' : ''}`}>
                    {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_3_ad315a88a0.jpg`} alt=""*/}
                    {/*     className="event-buttons-image"/>*/}
                    <div className="event-title">Sprint</div>
                </div>
                <div onClick={() => {
                    ChangeTable('Grid');
                }} className={`event-buttons ${event === "Grid" ? 'active' : ''}`}>
                    {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_4_d692e0c4cf.jpg`} alt=""*/}
                    {/*     className="event-buttons-image"/>*/}
                    <div className="event-title">Starting Grid</div>
                </div>
                <div onClick={() => {
                    ChangeTable('Race');
                }} className={`event-buttons ${event === "Race" ? 'active' : ''}`}>
                    {/*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_5_967d944162.jpg`} alt=""*/}
                    {/*     className="event-buttons-image"/>*/}
                    <div className="event-title">Race</div>
                </div>
                {/*<div onClick={() => {*/}
                {/*    ChangeTable('Replays');*/}
                {/*}} className={`event-buttons lust ${event === "Replays" ? 'active' : ''}`}>*/}
                {/*    /!*<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/button_6_c20d295fee.jpg`} alt=""*!/*/}
                {/*    /!*     className="event-buttons-image"/>*!/*/}
                {/*    <div className="event-title">Replays</div>*/}
                {/*</div>*/}

            </div>
        )
    }






    if (stageloading) {
        return (<div><CircularProgress color="inherit"/></div>)
    }
    if (stageloading === false) {

        // if(matches800) {
        //     return (
        //         <div>
        //             Перегляд на телефоні тимчасово недоступний
        //             <CircularProgress color="inherit"/></div>
        //
        //
        //     )
        // }

        // if (matches800) {
        //     return (
        //         <div className="contentmobile">
        //
        //             <div className="contentmobile-container">
        //                 <div className='main-table-list main-table-list-mobile'>
        //                     <div className="main-league-under-title main-league-under-title-mobile">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
        //                     <TopMenuNewMobile season={params.IdSeason} />
        //                     {/*<TopMenuSeasons   season={params.IdSeason} />*/}
        //                     <div>Перегляд на телефоні тимчасово недоступний
        //                         <CircularProgress color="inherit"/></div>
        //
        //                     <div className='main-table-list main-table-list-mobile'>
        //                         {/*<div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>*/}
        //                         <div className="main-league-under-title main-league-under-title-mobile">Constructor Standing <span className="main-season"> Season {params.IdSeason}</span></div>
        //                     </div>
        //
        //                 </div></div></div>
        //
        //
        //     )
        // }


        //console.log(stage[0]);

        if (!stage[0]) {
            navigate('/f1')
            return;
        }
        if (stage[0].stage.done) {

            const practice = _.sortBy(stage[0].resultlist, [function (o) {
                if (o.practice) {
                    return o.practice.finish;
                }


            }]);
            const sprint = _.sortBy(stage[0].resultlist, [function (o) {
                if (o.sprint) {
                    return Number(o.sprint.finish);
                }


            }]);
            const qualify = _.sortBy(stage[0].resultlist, [function (o) {
                if (o.qualify) {
                    return o.qualify.finish;
                }
            }]);
            const race = _.sortBy(stage[0].resultlist, [function (o) {
                if (o.race) {
                    return o.race.finish;
                }
            }]);
            //const racestart = _.orderBy(stage[0].resultlist.race, ['start'], ['asc']);
            const racestart = _.sortBy(stage[0].resultlist, [function (o) {
                if (o.race) {
                    return o.race.start;
                }
            }]);

            //console.log(stage[0])

            return (<div className={`content ${matches800 ? 'contentmobile' : ''}`}>
                <div className={`content-container ${matches800 ? 'contentmobile-container' : ''}`}>
                    <LinkBack/>
                    <ChooseStage />
                <div className="main-table-list">
                                <div className="main-league-list main-league-list-after-race">


                                    <TableHeader />


                                    {event === 'Race' && (race.map((result, index) => {
                                        if (!result.race) {
                                            return null;
                                        }
                                        //console.log(result.race);
                                        i++;

                                            // const pilot = _.find(stage[0].pilots, { id: result.pilot });
                                            // const team = _.find(stage[0].teams, { id: result.team });

                                        if(matches800){
                                            return (<div
                                                    className={`main-stage-row main-stage-row-mobile`}
                                                    key={index}>
                                                    <div className='position'><div className='pos pos-mobile'>{result.race.finish}</div> <div className={`gain gain-mobile ${(result.race.start - result.race.finish) > 0 ? 'gain-g' : 'gain-r'}`}>({result.race.start - result.race.finish})</div></div>
                                                    {/*<div className="main-name"><span className="fn"> {result.pilot.pilot.firstname} </span> <span className="n"> {result.pilot.pilot.Name}</span></div>*/}
                                                    <div className={`main-team-logo main-team-logo-${result.pilot.team.shorttitle}`}>
                                                        <img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${result.pilot.team.Logo.url}`} alt="" />

                                                    </div>
                                                    <div className={`main-team main-team-${result.pilot.team.shorttitle}`}>
                                                        {/*{result.pilot.team.name}*/}
                                                        {result.pilot.pilot.Name}
                                                    </div>
                                                    {/*{stratDetails ?*/}
                                                    {/*    <div className='header-gp-with-buttons'>*/}
                                                    {/*        <div>{result.race.start}</div>*/}
                                                    {/*        <div>{result.race.pitstops}</div>*/}
                                                    {/*        <div>{result.race.bestlaptime}</div>*/}
                                                    {/*        <div>{!result.race.penalties ? '-,' : `${result.race.penalties},`} {result.race.penalty >0 ? `-${result.race.penalty}p,` : ''} {result.race.superlicense > 0 ? `+${result.race.superlicense}SL` : ''}</div>*/}
                                                    {/*    </div>*/}
                                                    {/*    :*/}
                                                    {/*    <div className="strategy-block">*/}
                                                    {/*        {TyresStrategy(result.race.starttyre, 0, stage[0].laps)}*/}
                                                    {/*        {result.pitstop.map((pits, index) => {*/}

                                                    {/*            return (<div*/}
                                                    {/*                key={index}>{TyresStrategy(pits.tyre, pits.lap, stage[0].laps, pits.time, pits.repair)}</div>)*/}
                                                    {/*        })}</div>*/}

                                                    {/*}*/}
                                                    <div className="main-races">{result.race.dnf ? 'DNF' : result.race.racetime}</div>
                                                    <div className="main-points">{result.race.dnf ? 'DNF' : Points(result.race.finish, result.race.fastestlap ? 1 : 0, stage[0].season, 1 )-result.race.penalty} {result.sprint ? `(${result.sprint.points})` : ''}</div>

                                                </div>






                                            )
                                        }



                                        return (<div
                                                    className={`main-stage-row`}
                                                    key={index}>
                                                    <div className='position'><div className='pos'>{result.race.finish}</div> <div className={`gain ${(result.race.start - result.race.finish) > 0 ? 'gain-g' : 'gain-r'}`}>({result.race.start - result.race.finish})</div></div>
                                                    <div className="main-name"><span className="fn"> {result.pilot.pilot.firstname} </span> <span className="n"> {result.pilot.pilot.Name}</span></div>
                                                    <div className={`main-team-logo main-team-logo-${result.pilot.team.shorttitle}`}>
                                                        <img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${result.pilot.team.Logo.url}`} alt="" />

                                                    </div>
                                                    <div className={`main-team main-team-${result.pilot.team.shorttitle}`}>{result.pilot.team.name}</div>
                                                            {stratDetails ?
                                                                    <div className='header-gp-with-buttons'>
                                                                        <div>{result.race.start}</div>
                                                                        <div>{result.race.pitstops}</div>
                                                                        <div>{result.race.bestlaptime}</div>
                                                                        <div>{!result.race.penalties ? '-,' : `${result.race.penalties},`} {result.race.penalty >0 ? `-${result.race.penalty}p,` : ''} {result.race.superlicense > 0 ? `+${result.race.superlicense}SL` : ''}</div>
                                                                    </div>
                                                            :
                                                                    <div className="strategy-block">
                                                                    {TyresStrategy(result.race.starttyre, 0, stage[0].laps)}
                                                                    {result.pitstop.map((pits, index) => {

                                                                        return (<div
                                                                            key={index}>{TyresStrategy(pits.tyre, pits.lap, stage[0].laps, pits.time, pits.repair)}</div>)
                                                                    })}</div>

                                                            }
                                                <div className="main-races">{result.race.dnf ? 'DNF' : result.race.racetime}</div>
                                                <div className="main-points">{result.race.dnf ? 'DNF' : Points(result.race.finish, result.race.fastestlap ? 1 : 0, stage[0].season, 1 )-result.race.penalty} {result.sprint ? `(${result.sprint.points})` : ''}</div>

                                            </div>






                                            )

                                    }))}






                                    {event === 'Qualify' && (qualify.map((result, index) => {
                                        if (!result.race) {
                                            return null;
                                        }
                                        //console.log(result.race);
                                        i++;

                                        // const pilot = _.find(stage[0].pilots, { id: result.pilot });
                                        // const team = _.find(stage[0].teams, { id: result.team });
                                        return (<div
                                                className={`main-stage-row`}
                                                key={index}>
                                                <div>{result.qualify.finish}</div>
                                                <div className="main-name"><span className="fn"> {result.pilot.pilot.firstname} </span> <span className="n"> {result.pilot.pilot.Name}</span></div>
                                                <div className={`main-team-logo main-team-logo-${result.pilot.team.shorttitle}`}>
                                                    <img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${result.pilot.team.Logo.url}`} alt="" />

                                                </div>
                                                <div className={`main-team main-team-${result.pilot.team.shorttitle}`}>{result.pilot.team.name}</div>

                                                    <div className='header-gp-with-buttons'>
                                                        <div className='qTyres'>{Tyres(result.qualify.tyreq1, 'q1')}{Tyres(result.qualify.tyreq2, 'q2')}{Tyres(result.qualify.tyreq3, 'q3')}</div>
                                                        <div></div>
                                                        <div>{(result.qualify.q1 === '-' && result.qualify.dnf) ? 'DNF' : result.qualify.q1}</div>
                                                        <div>{(result.qualify.q2 === '-' && result.qualify.dnf) ? 'DNF' : result.qualify.q2}</div>
                                                        <div>{(result.qualify.q3 === '-' && result.qualify.dnf) ? 'DNF' : result.qualify.q3}</div>

                                                    </div>

                                                <div className="main-races"></div>
                                                <div className="main-points">{result.qualify.penalties}</div>

                                            </div>






                                        )

                                    }))}


                                            {event === 'Grid' &&
                                                (matches800 ? <StartingGrid2 sx={{transform: 'scale(0.1)'}} start={racestart} track={stage[0].track.TrackImage.url}
                                                                             season={stage[0].season} scale={0.45} mLeft={-610} mTop={0}/> : <StartingGrid2 sx={{transform: 'scale(0.35)'}} start={racestart}  scale={0.55} mLeft={-330} mTop={0} track={stage[0].track.TrackImage.url}
                                                               season={stage[0].season}/>)}



                                    {/*<TableHeader/>*/}

                                </div>

                </div>








                </div>
                </div>)
        }

        if (!stage[0].stage.done) {


            const date = moment(stage[0].stage.date, "YYYY-MM-DD");
            //console.log(stage[0])

            if (matches) {

                return (<div className="calendar">
                        <LinkBack/>
                        <div className="block-content " style={{fontSize: '12px'}}>

                            <div
                                className="gp-top-title">GP {stage[0].track.Country} - {event}</div>


                            <div className="next-stage-track">
                                <img
                                    src={(stage[0].track.trackphoto && `${process.env.REACT_APP_ADMIN_URL}${stage[0].track.trackphoto.url}`)}
                                    alt="" className="track-image-bg"/>
                                <div className="gp-track-title">SEASON {stage[0].stage.season}</div>
                                <div className="stage-details">{date.format('DD MMM')}</div>

                                <div className="gp-record"
                                     style={{display: (stage[0].stage.time_p ? (stage[0].stage.time_p === '00:00' ? '' : stage[0].stage.time_p) : 'none')}}>
                                    <div
                                        className="title">Practice: {date.format('ddd')} {stage[0].stage.time_p ? stage[0].stage.time_p.slice(0, 5) : '--:--'}</div>


                                </div>
                                <div className="gp-record">
                                    <div
                                        className="title">Qualification: {date.format('ddd')} {stage[0].stage.time_q.slice(0, 5)}</div>


                                </div>
                                <div className="gp-record">
                                    <div
                                        className="title">Race: {date.format('ddd')} {stage[0].stage.time_r.slice(0, 5)}</div>


                                </div>

                                <div className="gp-country" style={{marginTop: '20px'}}>{stage[0].laps} laps {stage[0].sprint ? "+ Sprint" : ""}</div>

                                <div className="gp-country" style={{marginTop: '60px'}}>{stage[0].track.Country}</div>
                                <div className="gp-flag"><img
                                    src={`${process.env.REACT_APP_ADMIN_URL}${stage[0].track.logo.url}`} alt=""
                                    className="gp-country-flag"/></div>
                                <div className="gp-title">{stage[0].track.title}</div>
                                <div className="gp-track-title">{stage[0].track.CircuitName}</div>
                                <div className="gp-record">World record: {stage[0].track.F1LapRecord}</div>
                                <div className="gp-tt">TimeTrial: {stage[0].track.timetrial}</div>
                                <div className="gp-length">Length: {stage[0].track.Length}</div>
                                <div className="gp-track"><img
                                    src={`${process.env.REACT_APP_ADMIN_URL}${stage[0].track.TrackImage.url}`}
                                    alt=""
                                    className="track-image"/></div>


                            </div>
                        </div>
                    </div>)

            }


            if (!matches) {
                return (<div className="calendar">
                        <LinkBack/>
                        <div className="block-content">
                            <div
                                className="gp-top-title">GP {stage[0].track.Country} - {stage[0].track.title} - {event}</div>
                            <div className="next-stage-info">
                                <div className="next-stage-track">
                                    <img
                                        src={(stage[0].track.trackphoto && `${process.env.REACT_APP_ADMIN_URL}${stage[0].track.trackphoto.url}`)}
                                        alt="" className="track-image-bg"/>
                                    <div className="gp-country">{stage[0].track.Country}</div>

                                    <div className="gp-flag"><img
                                        src={`${process.env.REACT_APP_ADMIN_URL}${stage[0].track.logo.url}`} alt=""
                                        className="gp-country-flag"/></div>
                                    <div className="gp-title">{stage[0].track.title}</div>
                                    <div className="gp-track-title">{stage[0].track.CircuitName}</div>
                                    <div className="gp-record">World record: {stage[0].track.F1LapRecord}</div>
                                    <div className="gp-tt">TimeTrial: {stage[0].track.timetrial}</div>
                                    <div className="gp-length">Length: {stage[0].track.Length}</div>
                                    <div className="gp-track"><img
                                        src={`${process.env.REACT_APP_ADMIN_URL}${stage[0].track.TrackImage.url}`}
                                        alt=""
                                        className="track-image"/></div>

                                </div>
                                <div className="next-stage-details">

                                    <div className="timeleft">

                                        <div className="next-gp-time-left">
                                            <div className="stage-details">SEASON {stage[0].stage.season}</div>
                                        </div>
                                        <div className="next-gp-time-left">


                                            <div className="stage-details">{stage[0].laps} laps {stage[0].sprint ? "+ Sprint" : ""}</div>
                                        </div>
                                        <div className="next-gp-time-left">
                                            <div className="next-gp-left">
                                                <div className="next-gp-title">GRAND PRIX WEEKEND</div>
                                                <div className="next-gp-timeleft">
                                                    <div className="day">--
                                                        <div className="under-time">DAYS</div>
                                                    </div>
                                                    <div className="dots">:</div>
                                                    <div className="hour">--
                                                        <div className="under-time">HRS</div>
                                                    </div>
                                                    <div className="dots">:</div>
                                                    <div className="minutes">--
                                                        <div className="under-time">MINS</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-gp-right"><img
                                                src={`${process.env.REACT_APP_ADMIN_URL}/uploads/LOGOUA_a75979011e.png`}
                                                alt=""
                                                className="league-logo"/></div>

                                        </div>
                                        <div className="next-gp-time-left">
                                            <div className="stage-details">{date.format('DD MMM')}</div>
                                        </div>
                                        <div className="events">
                                            <div className="stage-time"
                                                 style={{display: (stage[0].stage.time_p ? (stage[0].stage.time_p === '00:00' ? '' : stage[0].stage.time_p) : 'none')}}>
                                                <div className="title">Practice:</div>
                                                <div className="day">{date.format('ddd')}</div>
                                                <div
                                                    className="time">{stage[0].stage.time_p ? stage[0].stage.time_p.slice(0, 5) : '--:--'}</div>
                                            </div>
                                            <div className="stage-time">
                                                <div className="title">Qualification:</div>
                                                <div className="day">{date.format('ddd')}</div>
                                                <div className="time">{stage[0].stage.time_q.slice(0, 5)}</div>
                                            </div>
                                            <div className="stage-time">
                                                <div className="title">Race:</div>
                                                <div className="day">{date.format('ddd')}</div>
                                                <div className="time">{stage[0].stage.time_r.slice(0, 5)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
            }
        }


    }


}
export default Stage;