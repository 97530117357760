import {Link} from "react-router-dom";
import React from 'react';

const TopMenu = ({season})=>{
    if(!season){season = 4}
    return(
        <div className="title-page-background" id='menu-color'>
            <Link className="title-page" to={`/`}>Main</Link>
            <Link className="title-page" to={`/f1`}>Seasons</Link>
            {/*<Link className="title-page" to={`/season/${season}`}>Season {season}</Link>*/}
            <Link className="title-page" to={`/calendar/${season}`}>Calendar</Link>
            <Link className="title-page" to={`/pilotstanding/${season}`}>PStanding</Link>
            <Link className="title-page" to={`/constructorstanding/${season}`}>CStanding</Link>
            <Link className="title-page" to={`/pilots/${season}`}>Pilots</Link>
            <Link className="title-page" to={`/rating`}>Rating</Link>
        </div>
    )
}
export default TopMenu;