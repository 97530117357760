import React from 'react';
import {createContext, useEffect, useReducer} from 'react';
import axios from 'axios';
import {reducer, initialFormula} from './F1Reducer';

const qs = require('qs');


const FormulaContext = createContext({});
const {Provider} = FormulaContext;

const F1ContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialFormula);


    const getSeason = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/seasons`);
            dispatch({type: 'FETCH_SEASONS', payload: res.data});
            dispatch({type: 'FETCH_SEASONS_LOADING_FINISH'})
        } catch (error) {
        }
    };

    const getlastRace = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/racestands`);
            dispatch({type: 'FETCH_RACE_STAND', payload: res.data});
            dispatch({type: 'FETCH_RACE_STAND_LOADING'})
        } catch (error) {
        }
    };
    const getStartRace = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/racestarts`);
            dispatch({type: 'FETCH_RACE_START', payload: res.data});
            dispatch({type: 'FETCH_RACE_START_LOADING'})
        } catch (error) {
        }
    };

    const getStages = async (season) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/stages`, {params: {season: season}});
            const seasondetail = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/seasons/${season}`);
            dispatch({type: "FETCH_SEASON_DETAILS", payload: seasondetail.data});
            dispatch({type: "FETCH_STAGES", payload: res.data});
            dispatch({type: "FETCH_STAGES_FINISH", payload: false});

        } catch (error) {
        }
    };

    const getNextStageId = async ()=>{
        try{
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/stages`, {params: {next: true}});
            //console.log(res.data);
            dispatch({type: "FETCH_NEXT_GP", payload: res.data});
        }
        catch(error){}
    }



    const getStageResults = async (stage) => {
        try {
            //console.log(stage);
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/stages`, {
                params: {
                    id: stage,
                }

            });
            dispatch({type: "FETCH_STAGE", payload: res.data});
            dispatch({type: "FETCH_STAGE_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }
    const getStageRes = async (stage) => {
        try {

            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/gpres/`, {
                params: {
                    seasonstage: stage,
                }

            });
            //console.log(res.data);
            dispatch({type: "FETCH_STAGE", payload: res.data});
            dispatch({type: "FETCH_STAGE_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }

    const getSeasonRes = async (idSeason) => {
        try {
            // console.log(idSeason);
            // const query = qs.stringify({
            //     filters: {
            //         stage: {
            //             season: {$eq: idSeason}
            //         },
            //     },
            // });
            // const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/gpres?${query}`);
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/gpres/`, {
                params: {
                        season: idSeason,
                },

            });
            // console.log(res.data);
            dispatch({type: "FETCH_SEASON_RESULTS", payload: res.data});
            dispatch({type: "FETCH_SEASON_RESULTS_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }
    const getAllRes = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/gpres/`);

            dispatch({type: "FETCH_ALL_RESULTS", payload: res.data});
            dispatch({type: "FETCH_ALL_RESULTS_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }

    const getSeasonPilots = async (idSeason) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/season-pilots/`, {
                params: {
                    season: idSeason,
                },

            });
            // console.log(res.data);
            dispatch({type: "FETCH_SEASON_PILOTS", payload: res.data});
            dispatch({type: "FETCH_SEASON_PILOTS_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }

    const getPilots = async (idSeason) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ADMIN_URL}/pilots/`);
            // console.log(res.data);
            dispatch({type: "FETCH_PILOTS", payload: res.data});
            dispatch({type: "FETCH_PILOTS_END_LOADING", payload: false});

        } catch (error) {
            //console.log('nema');
        }
    }



    return (
        <Provider
            value={{
                ...state,
                getSeason,
                getStages,
                getStageRes,
                getSeasonRes,
                getNextStageId,
                getSeasonPilots,
                getAllRes,
                getPilots,
                getlastRace,
                getStartRace,
                dispatch,

            }}
        >
            {props.children}
        </Provider>
    );
};
export {F1ContextProvider};
export default FormulaContext;
