const TyresStrategy = (tyre, lap, laps, time, rr) => {
    //console.log(lap)
    const persent = (lap / laps) * 100;
    // console.log(tyre, lap, laps);
    // console.log(persent);

    const Pitstop = ({tyreimg, p, t, rr, c})=>{
        return (<div className='strategy'><img src={`${process.env.REACT_APP_ADMIN_URL}${tyreimg}`}
                          style={{left: `${p}%`}} className={`tyre-icon `} title={`${(rr==='DT' ? 'Drive-through penalty' : 'Pitstop')} on ${lap} lap, ${t} sec, ${(rr!='none' ? rr : 'no repair')}`}/>
            <div className="tyre-line" style={{left: `calc(${p}% + 15px)`, backgroundColor: c, opacity: '100%'}}></div>
            <div className="laps">{`${(rr==='DT' ? 'Drive-through penalty' : 'Pitstop')} on ${lap} lap, ${t} sec, ${(rr!='none' ? rr : 'no repair')}`}111</div>
        </div>)
    }
    if(rr==='DNF')return (<Pitstop tyreimg='/uploads/dnf_3_697511b188.png' p={persent} t={time} rr={rr} c='gray' />);
    if(rr==='DSQ')return (<Pitstop tyreimg='/uploads/dsq_8ad9f7fe52.png' p={persent} t={time} rr={rr} c='black' />);
    if(rr==='DT')return (<Pitstop tyreimg='/uploads/dt_563018ed02.png' p={persent} t={time} rr={rr} c='none' />);
    if(rr==='RF')return (<Pitstop tyreimg='/uploads/rf_6152730d87.png' p={persent} t={time} rr={rr} c='red' />);
    switch (tyre) {
        case 'soft':
            return (
                <Pitstop tyreimg='/uploads/soft_e90144a430.png' p={persent} t={time} rr={rr} c='#9d0300' />
                );
        case 'medium':
            return (<Pitstop tyreimg='/uploads/medium_048ad8ea7c.png' p={persent} t={time} rr={rr} c='#ffcc00' />);
        case 'hard':
            return (<Pitstop tyreimg='/uploads/hard_302a20b791.png' p={persent} t={time} rr={rr} c='#f1f1f1' />);
        case 'int':
            return (<Pitstop tyreimg='/uploads/int_38e8ddb6b1.png' p={persent} t={time} rr={rr} c='#135e00' />);
        case 'wet':
            return (<Pitstop tyreimg='/uploads/wet_96e9dce19c.png' p={persent} t={time} rr={rr} c='#002891' />);
        case null:
            return (<div align='center'>No details</div>);
        default:
            return (<Pitstop tyreimg='/uploads/dnf_3_697511b188.png' p={persent} t={time} rr={rr} c='#323232' />);


    }
}
export default TyresStrategy;