import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

//import './Web.scss';
import './Content2.scss';
import Points from "./Points";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import TyresStrategy from "./TyresStrategy";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";


const StandingTable = ({idSeason}) => {
    //let params = useParams();
    const {getlastRace, racestandLoading, racestand, getSeasonRes, seasonResults, seasonResultsLoading} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);
    const [listNumberDriver, setNumberDriver]= useState(0);
    const [stageNumber, setStageNumber] = useState(0)



    /***********************************/ /***********************************/ /***********************************/

    const driverSeasonStanding = useMemo(()=>{

        let pilotlist = [];




        seasonResults.forEach((stage, index) => {
            // console.log(stage);

            if(!stage.test || testShow){
                stage.resultlist.forEach((pilotRes, index) => {
                    //console.log(pilotRes)

                    if (pilotRes.race.points >= 0) {
                        const pilotresults = _.find(pilotlist, {pilot: pilotRes.pilot.pilot.Name});
                        if (pilotRes.pilot.pilot.AI && pilotsAI || !pilotRes.pilot.pilot.AI) {
                            if (!pilotresults) {
                                let pppp = Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, idSeason, 0);
                                if (pilotRes.race.dnf) pppp = 0;
                                pilotlist.push({
                                    pilot: pilotRes.pilot.pilot.Name,
                                    pilot_full: pilotRes.pilot.pilot,
                                    number: pilotRes.pilot.pilot.number,
                                    team: pilotRes.pilot.team.name,
                                    avatar: GetAvatar(pilotRes.pilot.pilot.avatar, idSeason, 1),
                                    races: 1,
                                    team_full: pilotRes.pilot.team,
                                    sl: pilotRes.race.superlicense,
                                    points: pppp,
                                })
                            }
                            if (pilotresults) {

                                pilotlist.forEach((driver, index) => {
                                    let sprintpoints;
                                    if (!pilotRes.sprint) {
                                        sprintpoints = 0;
                                    }
                                    if (pilotRes.sprint) {
                                        sprintpoints = pilotRes.sprint.points;
                                    }
                                    if (driver.pilot === pilotresults.pilot) {
                                        let ppp = Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, idSeason, 0);
                                        if (pilotRes.race.dnf) ppp = 0;
                                        pilotlist[index] = {
                                            pilot: driver.pilot,
                                            pilot_full: pilotRes.pilot.pilot,
                                            number: driver.number,
                                            avatar: GetAvatar(pilotRes.pilot.pilot.avatar, idSeason, 1),
                                            team: pilotRes.pilot.team.name,
                                            races: driver.races + 1,
                                            team_full: pilotRes.pilot.team,
                                            sl: driver.sl + pilotRes.race.superlicense,
                                            points: driver.points + ppp + sprintpoints
                                        }
                                    }
                                })
                            }
                        }
                    }

                })
            }

        })
        const pointslist = _.sortBy(pilotlist, [function (o) {
            return o.points;
        }]);

        const driverStandingList = _.orderBy(pointslist, ['points'], ['desc']);
        return driverStandingList;
    },[seasonResults]);


    /***********************************/ /***********************************/ /***********************************/

    useEffect(() => {
        getSeasonRes(idSeason);

    }, [idSeason]);
    useEffect(() => {
        getlastRace();

    }, []);





    if (racestandLoading || seasonResultsLoading) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }

    //console.log(seasonResults)
    if(seasonResults.length == 0) {
        console.log('lol');
        return (
            <>Немає Данних</>
        )
    }
    if(driverSeasonStanding.length === 0){
        return (
            <><CircularProgress color="inherit"/> </>
        )
    }
    let newPilotList = [];
    const pilotsNewList = driverSeasonStanding.map((driver, index) => {

        const pilotFinded = _.find(racestand[0].driver, {pilot: {Name: driver.pilot}});
        if(pilotFinded){
           //console.log(pilotFinded)
            const lustRacePosition = racestand[0].driver.findIndex((pilot) => pilot.pilot.Name === driver.pilot);
            //console.log(driver)
            newPilotList.push({
                prevPosition: index+1,
                avatar: driver.avatar,
                pilot: driver.pilot_full,
                team: driver.team_full,
                points: driver.points,
                races: driver.races,
                sl: driver.sl,
            })
        }
        if(!pilotFinded){
            //console.log(pilotFinded)
            //const lustRacePosition = racestand[0].driver.findIndex((pilot) => pilot.pilot.Name === driver.pilot);
            //console.log(driver)
            newPilotList.push({
                prevPosition: index+1,
                avatar: driver.avatar,
                pilot: driver.pilot_full,
                team: driver.team_full,
                points: driver.points,
                races: driver.races,
                sl: driver.sl,
            })
        }


    });









    const newPilotListSortByPoints = _.sortBy(newPilotList, [function (o) {
        return o.points;
    }]);

    const newPilotListOrderByPoints = _.orderBy(newPilotListSortByPoints, ['points'], ['desc']);

    /***********************************/

    const OpenInfo = (listNumber)=>{
        if(listNumber === listNumberDriver) setNumberDriver(0);
        if(listNumber != listNumberDriver) setNumberDriver(listNumber);
        //console.log(seasonResults.length)
        setStageNumber(seasonResults.length-1);
    }

    /***********************************/ /***********************************/ /***********************************/
    const Driver = (p)=>{
        console.log(newPilotListOrderByPoints)
        const avatarFinded = _.find(newPilotListOrderByPoints[p].pilot.avatar, {name: idSeason});

        return(

            <div className={`main-first-place main-first-place-background-gradient main-first-place-${newPilotListOrderByPoints[p].team.shorttitle} ${p===0 ? 'main-first-place-first' : (p===2 && 'main-first-place-third')}`}>
                <div className="main-team-name-vertical">{newPilotListOrderByPoints[p].team.name}
                    {/*{racestand[0].driver[p].team.name}*/}
                </div>
                <div className="main-pilot-place">{p+1}<span className="st">{p===0 ? 'st' : (p===2 ? 'nd' : 'rd')}</span></div>

                <div className="main-pilot-name">

                    {newPilotListOrderByPoints[p].pilot.firstname}
                    {/*{racestand[0].driver[p].pilot.firstname}*/}
                </div>
                <div className="main-pilot-nickname">
                    {newPilotListOrderByPoints[p].pilot.Name}
                    {/*{racestand[0].driver[p].pilot.Name}*/}
                </div>

                <div className="main-pilot-number outline">{newPilotListOrderByPoints[p].pilot.number}
                    {/*{racestand[0].driver[p].pilot.number}*/}
                </div>

                <div className="main-pilot-div-avatar"><img src={`https://f1admin.serdyuks.com${(avatarFinded ? avatarFinded.url : GetAvatar(newPilotListOrderByPoints[p].pilot.avatar, idSeason, 1))}`} alt="" className={`main-pilot-avatar${p===0 ? 'first' : (p===2 ? 'third' : 'second')}`} /></div>
                <div className="main-name-background"> </div>
            </div>
        )
    }


    /***********************************/ /***********************************/ /***********************************/
    const DriverInfo = ({driver})=>{



        const resList = seasonResults[stageNumber].resultlist;
        //console.log(seasonResults[stageNumber])
        console.log(resList.length)
        if(resList.length == 0) setStageNumber(stageNumber-1)
        const driverItem = resList.map((pilotFromArray)=>{
            //console.log(pilotFromArray.pilot.pilot.Name+' - '+driver)

            if(pilotFromArray.pilot.pilot.Name == driver){
                //console.log('true')

                return (
                    <>

                        <div className="driver-info-stage-container-position">Position: {pilotFromArray.race.finish}
                        </div>
                        <div
                            className="driver-info-stage-container-points">Points: {Points(pilotFromArray.race.finish, pilotFromArray.race.fastestlap ? 1 : 0, idSeason, 0)}
                        </div>
                        <div className="driver-info-stage-container-start">Start: {pilotFromArray.race.start}
                        </div>

                        <div className='strategy-title'>Strategy:</div>
                        <div className="strategy-block">

                            {TyresStrategy(pilotFromArray.race.starttyre, 0, seasonResults[stageNumber].laps)}
                            {pilotFromArray.pitstop.map((pits, index) => {

                                return (<div
                                    key={index}>{TyresStrategy(pits.tyre, pits.lap, seasonResults[stageNumber].laps, pits.time, pits.repair)}</div>)
                            })}</div>
                    </>
                )
            }
        return null;
        })
        return driverItem;
    }


    /***********************************/ /***********************************/ /***********************************/

    return (
        <>


        <div className="web-calendar">

            <div className="web-calendar-container">

                {/*<div className={`web-first-place web-first-place-${newPilotListOrderByPoints[0].team.shorttitle}`}>*/}
                {/*    <div className="web-team-name-vertical">{newPilotListOrderByPoints[0].team.name}</div>*/}
                {/*    <div className="web-pilot-place">1st</div>*/}
                {/*    <div className="web-pilot-number outline">{newPilotListOrderByPoints[0].pilot.number}</div>*/}
                {/*    <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${newPilotListOrderByPoints[0].avatar}`} alt="" className='web-pilot-avatar' /></div>*/}


                {/*</div>*/}
                <div className='main-table-list'>
                    {/*<div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>*/}
                    <div className="main-league-under-title">Driver Standing <span className="main-season"> Season {idSeason}</span></div>


                    <div className="main-league-list main-league-list-after-race">
                        <div className="content-container-firstthreedrivers">
                            {Driver(1)}
                            {Driver(0)}
                            {Driver(2)}
                        </div>
                        <div className={`main-pilot-row`}>
                            <div></div>
                            <div className="main-name"></div>
                            <div className="main-team-logo"></div>
                            <div className="main-team"></div>
                            <div></div>
                            <div className="main-sl">SL</div>
                            <div className="main-races">Races</div>
                            <div className="main-points">Points</div>

                        </div>





                                    {newPilotListOrderByPoints.map((pilot, index) => {

                                        // if(pilot.fl){
                                        //     pilotfl = pilot;
                                        // }
                                        if(index===10)return (

                                        <div className="view-full-table">
                                            <Link to={`/pilotstanding/${idSeason}`}>View Full Table</Link>

                                        </div>
                                        )
                                        if(index>10) return null;




                                        return (
                                            <div key={index}>
                                            <div className={`main-pilot-row  main-pilot-row-after-race ${index === pilotOnSide  ? 'main-first-row' : ''}`} key={index} onClick={()=>{OpenInfo(index+1)}}>
                                                <div className="main-place">{index+1}
                                                    {/*{ PlaceChange(pilot.prevPosition, index + 1)}*/}
                                                </div>
                                                <div className="main-name"> <span className="fn"> {pilot.pilot.firstname} </span> <span className="n"> {pilot.pilot.Name}</span></div>
                                                <div className={`main-team-logo main-team-logo-${pilot.team.shorttitle}`}><img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`} alt="" /></div>
                                                <div className={`main-team main-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>
                                                <div></div>
                                                <div className="main-sl">{pilot.sl}</div>
                                                <div className="main-races">{pilot.races}</div>
                                                <div className="main-points">{pilot.points}</div>

                                            </div>
                                                <div className={`driver-info ${listNumberDriver === index+1 ? `driver-info-opened` : `driver-info-closed`} driver-info-${pilot.team.shorttitle}`}>
                                                    <img src={`https://f1admin.serdyuks.com${pilot.avatar}`} alt="" className={`driveravatar`} />
                                                    <div className="driver-info-name">
                                                        {pilot.pilot.firstname}
                                                    </div>
                                                    <div className="driver-info-nickname">
                                                        {pilot.pilot.Name}

                                                    </div>
                                                    <div className="driver-info-number outline">{pilot.pilot.number}
                                                    </div>



                                                    <div className='driver-info-stage-container'>
                                                        <div className="driver-info-stage-container-stage">
                                                            <div>
                                                            <ArrowLeftIcon sx={{
                                                                color: '#c2c2c2',
                                                                padding: '0px',
                                                                display: (stageNumber-1 > -1 ? 'block' : 'none'),
                                                                cursor: 'pointer',
                                                                width: '25px'
                                                            }} onClick={()=>{setStageNumber(stageNumber-1)}}/>
                                                            </div>
                                                            <div className="stage">Stage</div>
                                                            <div className="number">{stageNumber + 1}</div>
                                                            <div className="driver-info-country">
                                                                {seasonResults[stageNumber].track.Country}
                                                            </div>
                                                            <img
                                                                src={`https://f1admin.serdyuks.com${seasonResults[stageNumber].track.logo.url}`}
                                                                alt="" className="flag"/>
                                                            <div>
                                                            <ArrowRightIcon sx={{
                                                                color: '#c2c2c2',
                                                                padding: '0px',
                                                                display: ((stageNumber+1 < seasonResults.length && seasonResults[stageNumber+1].resultlist.length > 0 )? 'block'  : 'none'),
                                                                cursor: 'pointer',
                                                                width: '25px'
                                                            }} onClick={()=>{setStageNumber(stageNumber+1)}}/>
                                                            </div>
                                                        </div>

                                                        <DriverInfo driver={pilot.pilot.Name}/>
                                                    </div>




                                                </div>
                                            </div>
                                        )

                                    })}





                    </div>

                </div>
            </div>
        </div>
        </>
    )

    /*--------------------------------------------------------------------------------*/

}

export default StandingTable;