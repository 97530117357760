import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

import './Web.scss';
import Points from "./Points";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';


const WebAfterRaceStanding = () => {
    let params = useParams();
    const {getlastRace, racestandLoading, racestand, getSeasonRes, seasonResults, seasonResultsLoading} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);



    const driverSeasonStanding = useMemo(()=>{

        let pilotlist = [];

        seasonResults.forEach((stage, index) => {
            // //console.log(stage);

            if(!stage.test || testShow){
                stage.resultlist.forEach((pilotRes, index) => {


                    if (pilotRes.race.points >= 0) {
                        const pilotresults = _.find(pilotlist, {pilot: pilotRes.pilot.pilot.Name});
                        if (pilotRes.pilot.pilot.AI && pilotsAI || !pilotRes.pilot.pilot.AI) {
                            if (!pilotresults) {

                                console.log(pilotRes.pilot.pilot.Name + Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0))
                                pilotlist.push({
                                    pilot: pilotRes.pilot.pilot.Name,
                                    pilot_full: pilotRes.pilot.pilot,
                                    number: pilotRes.pilot.pilot.number,
                                    team: pilotRes.pilot.team.name,
                                    avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                    races: 1,
                                    team_full: pilotRes.pilot.team,

                                    points: (Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0)) ? Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0) : 0,
                                })
                            }
                            if (pilotresults) {

                                pilotlist.forEach((driver, index) => {
                                    let sprintpoints;
                                    if (!pilotRes.sprint) {
                                        sprintpoints = 0;
                                    }
                                    if (pilotRes.sprint) {
                                        sprintpoints = pilotRes.sprint.points;
                                    }
                                    if (driver.pilot === pilotresults.pilot) {
                                        pilotlist[index] = {
                                            pilot: driver.pilot,
                                            pilot_full: pilotRes.pilot.pilot,
                                            number: driver.number,
                                            avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                            team: pilotRes.pilot.team.name,
                                            races: driver.races + 1,
                                            team_full: pilotRes.pilot.team,

                                            points: driver.points + ((Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0)) ? Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0) : 0) + sprintpoints
                                        }
                                    }
                                })
                            }
                        }
                    }

                })
            }

        })
        const pointslist = _.sortBy(pilotlist, [function (o) {
            return o.points;
        }]);

        const driverStandingList = _.orderBy(pointslist, ['points'], ['desc']);
        return driverStandingList;
    },[seasonResults]);

    useEffect(() => {
        getSeasonRes(params.IdSeason);

    }, []);
    useEffect(() => {
        getlastRace();

    }, []);





    if (racestandLoading || seasonResultsLoading) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }

 // //console.log(driverSeasonStanding)
    let newPilotList = [];
    const pilotsNewList = driverSeasonStanding.map((driver, index) => {
        ////console.log(racestand[0].driver)
        const pilotFinded = _.find(racestand[0].driver, {pilot: {Name: driver.pilot}});
        if(pilotFinded){
           ////console.log(pilotFinded)
            const lustRacePosition = racestand[0].driver.findIndex((pilot) => pilot.pilot.Name === driver.pilot);
            console.log(driver+lustRacePosition)
            newPilotList.push({
                prevPosition: index+1,
                avatar: driver.avatar,
                pilot: driver.pilot_full,
                team: driver.team_full,
                points: driver.points + Points(lustRacePosition, driver.fl ? 1 : 0, params.IdSeason, 0),
                races: driver.races+1,
            })
        }
        if(!pilotFinded){
            ////console.log(pilotFinded)
            //const lustRacePosition = racestand[0].driver.findIndex((pilot) => pilot.pilot.Name === driver.pilot);
            ////console.log(driver)
            newPilotList.push({
                prevPosition: index+1,
                avatar: driver.avatar,
                pilot: driver.pilot_full,
                team: driver.team_full,
                points: driver.points,
                races: driver.races,
            })
        }


    });


    racestand[0].driver.map((driver, index) => {
        const pilotFinded = _.find(newPilotList, {pilot: {Name: driver.pilot.Name}});
        if(!pilotFinded){
            //console.log(driver)
            if(!driver.pilot.AI) {

                ////console.log(driver)
                newPilotList.push({
                    prevPosition: index + 1,
                    avatar: GetAvatar(driver.pilot.avatar, params.IdSeason),
                    pilot: driver.pilot,
                    team: driver.team,
                    points: Points(index + 1, driver.fl ? 1 : 0, params.IdSeason, 0),
                    races: (driver.races ? driver.races : 0) + 1,
                })
            }
        }
    });

    //console.log(newPilotList)






    const newPilotListSortByPoints = _.sortBy(newPilotList, [function (o) {
        return o.points;
    }]);

    const newPilotListOrderByPoints = _.orderBy(newPilotListSortByPoints, ['points'], ['desc']);
    ////console.log(newPilotList)
   // //console.log(newPilotListOrderByPoints)



    const PlaceChange = (prev, now) => {
        const different = prev - now;
        if(different<0){
            return (<div  className="web-place-after-race">
                    <span><KeyboardArrowDownIcon style={{color: '#930000'}} /></span>
                    <span>{now}</span>
                    <span style={{fontSize: '10px', lineHeight:'16px', textAlign: 'left'}}>({prev})</span>

                </div>
            )
        }
            if(different>0) {
                return (
                    <div  className="web-place-after-race">
                        <span><KeyboardArrowUpIcon  style={{color: '#226700'}} /></span>
                        <span>{now}</span>
                        <span style={{fontSize: '10px', lineHeight:'16px', textAlign: 'left'}}>({prev})</span>
                    </div>
                )
            }
                if(different==0) {
                    return (
                        <div  className="web-place-after-race">


                            <span><RemoveIcon  style={{color: '#4b4b4b'}} /></span>
                            <span>{now}</span>
                            <span style={{fontSize: '10px', lineHeight:'16px', textAlign: 'left'}}>({prev})</span>
                        </div>
                    )
                }
    }



   // //console.log(driverSeasonStanding)
    let pilotfl = {};
    return (
        <div className="web-calendar">

            <div className="web-calendar-container">

                <div className={`web-first-place web-first-place-${newPilotListOrderByPoints[0].team.shorttitle}`}>
                    <div className="web-team-name-vertical">{newPilotListOrderByPoints[0].team.name}</div>
                    <div className="web-pilot-place">1st</div>
                    <div className="web-pilot-number outline">{newPilotListOrderByPoints[0].pilot.number}</div>
                    <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${newPilotListOrderByPoints[0].avatar}`} alt="" className='web-pilot-avatar' /></div>


                </div>
                <div className='web-table-list'>
                    <div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                    <div className="web-league-under-title">Driver Standing <span className="web-season"> Season {params.IdSeason}</span></div>
                    <div className="web-league-list web-league-list-after-race">

                        <div className={`web-pilot-row`}>
                            <div></div>
                            <div className="web-name"></div>
                            <div className="web-team-logo"></div>
                            <div className="web-team"></div>
                            <div className="web-races">Races</div>
                            <div className="web-points">Points</div>

                        </div>

                        {newPilotListOrderByPoints.map((pilot, index) => {

                            // if(pilot.fl){
                            //     pilotfl = pilot;
                            // }
                            //console.log(pilot.points)

                            return (
                                <div className={`web-pilot-row  web-pilot-row-after-race ${index === pilotOnSide  ? 'first-row' : ''}`} key={index}>
                                    <div className="web-place">{ PlaceChange(pilot.prevPosition, index + 1)}</div>
                                    <div className="web-name"> <span className="fn"> {pilot.pilot.firstname} </span> <span className="n"> {pilot.pilot.Name}</span></div>
                                    <div className={`web-team-logo web-team-logo-${pilot.team.shorttitle}`}><img className="web-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`} alt="" /></div>
                                    <div className={`web-team web-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>
                                    <div className="web-races">{pilot.races}</div>
                                    <div className="web-points">{pilot.points}</div>

                                </div>
                            )

                        })}
                        {/*<div className="web-row-fl"><span className="fl">Fastest lap</span> <span className="name">{pilotfl.pilot.firstname} {pilotfl.pilot.Name}</span> <span>{pilotfl.team.name} </span> <span>{pilotfl.timelap}</span> <span>+1 point</span>  </div>*/}
                    </div>

                </div>
            </div>
        </div>
    )

    /*--------------------------------------------------------------------------------*/

}

export default WebAfterRaceStanding;