const GetAvatarPos = (avatars, season, pos) => {
    //console.log(avatars)
    if(avatars[0]){
        if(avatars[0].alternativeText==='ai')return avatars[0].url

    }
    let ava = avatars.find(avatar => avatar.alternativeText == `${season}-${pos}`);
    if(!ava) ava =  avatars.find(avatar => avatar.name == `${season}`);

    return ava ? ava.url : '/uploads/avatarf1_6ac442b41e.png';


}

export  default GetAvatarPos;