import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

//import './Web.scss';
import './Content2.scss';
import Points from "./Points";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/Remove';
import TyresStrategy from "./TyresStrategy";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";


const StandingTable = ({idSeason, limit}) => {
    //let params = useParams();
    const {getSeasonPilots,seasonPilots,seasonPilotsLoading,getlastRace, racestandLoading, racestand, getSeasonRes, seasonResults, seasonResultsLoading} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);
    const [listNumberDriver, setNumberDriver]= useState(0);
    const [stageNumber, setStageNumber] = useState(0)





    useEffect(() => {
       // getSeasonRes(idSeason);
        getSeasonPilots(idSeason);
    }, [idSeason]);
    // useEffect(() => {
    //     getlastRace();
    //
    // }, []);





    if (seasonPilotsLoading) {
        return (
            <div><CircularProgress color="inherit"/></div>
        )
    }

    //console.log(seasonPilots)

 // //console.log(driverSeasonStanding)
    let newPilotList = [];


    const OpenInfo = (listNumber)=>{
        if(listNumber === listNumberDriver) setNumberDriver(0);
        if(listNumber != listNumberDriver) setNumberDriver(listNumber);
        ////console.log(seasonResults.length)
        setStageNumber(seasonResults.length-1);
    }


    const Driver = (p)=>{
        const avatarFinded = _.find(seasonPilots[p].pilot.avatar, {name: `${idSeason}`});

        return(

            <div className={`main-first-place main-first-place-background-gradient main-first-place-${seasonPilots[p].team.shorttitle} ${p===0 ? 'main-first-place-first' : (p===2 && 'main-first-place-third')}`}>
                <div className="main-team-name-vertical">{seasonPilots[p].team.name}
                    {/*{racestand[0].driver[p].team.name}*/}
                </div>
                <div className="main-pilot-place">{p+1}<span className="st">{p===0 ? 'st' : (p===2 ? 'nd' : 'rd')}</span></div>

                <div className="main-pilot-name">

                    {seasonPilots[p].pilot.firstname}
                    {/*{racestand[0].driver[p].pilot.firstname}*/}
                </div>
                <div className="main-pilot-nickname">
                    {seasonPilots[p].pilot.Name}
                    {/*{racestand[0].driver[p].pilot.Name}*/}
                </div>

                <div className="main-pilot-number outline">{seasonPilots[p].pilot.number}
                    {/*{racestand[0].driver[p].pilot.number}*/}
                </div>

                <div className="main-pilot-div-avatar"><img src={`https://f1admin.serdyuks.com${(avatarFinded ? avatarFinded.url : seasonPilots[p].pilot.avatar[0].url)}`} alt="" className={`main-pilot-avatar${p===0 ? 'first' : (p===2 ? 'third' : 'second')}`} /></div>
                <div className="main-name-background"> </div>
            </div>
        )
    }
    // //console.log(seasonResults)
    // //console.log(seasonResults[stageNumber].resultlist)
    const DriverInfo = ({driver})=>{



        const ResList = seasonResults[stageNumber].resultlist;
        ////console.log(seasonResults[stageNumber])
        const driverItem = ResList.map((pilotFromArray)=>{
            ////console.log(pilotFromArray.pilot.pilot.Name+' - '+driver)
            if(pilotFromArray.pilot.pilot.Name == driver){
                ////console.log('true')
                return (
                    <>

                        <div className="driver-info-stage-container-position">Position: {pilotFromArray.race.finish}
                        </div>
                        <div
                            className="driver-info-stage-container-points">Points: {Points(pilotFromArray.race.finish, pilotFromArray.race.fastestlap ? 1 : 0, idSeason, 0)}
                        </div>
                        <div className="driver-info-stage-container-start">Start: {pilotFromArray.race.start}
                        </div>

                        <div className='strategy-title'>Strategy:</div>
                        <div className="strategy-block">

                            {TyresStrategy(pilotFromArray.race.starttyre, 0, seasonResults[stageNumber].laps)}
                            {pilotFromArray.pitstop.map((pits, index) => {

                                return (<div
                                    key={index}>{TyresStrategy(pits.tyre, pits.lap, seasonResults[stageNumber].laps, pits.time, pits.repair)}</div>)
                            })}</div>
                    </>
                )
            }
        return null;
        })
        // //console.log(driverItem);
        // if(driverItem == null) //console.log('null')
        return driverItem;





    }

    return (
        <>


        <div className="web-calendar">

            <div className="web-calendar-container">

                {/*<div className={`web-first-place web-first-place-${newPilotListOrderByPoints[0].team.shorttitle}`}>*/}
                {/*    <div className="web-team-name-vertical">{newPilotListOrderByPoints[0].team.name}</div>*/}
                {/*    <div className="web-pilot-place">1st</div>*/}
                {/*    <div className="web-pilot-number outline">{newPilotListOrderByPoints[0].pilot.number}</div>*/}
                {/*    <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${newPilotListOrderByPoints[0].avatar}`} alt="" className='web-pilot-avatar' /></div>*/}


                {/*</div>*/}
                <div className='main-table-list'>
                    {/*<div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>*/}
                    <div className="main-league-under-title">Driver Standing <span className="main-season"> Season {idSeason}</span></div>


                    <div className="main-league-list main-league-list-after-race">
                        <div className="content-container-firstthreedrivers">
                            {Driver(1)}
                            {Driver(0)}
                            {Driver(2)}
                        </div>
                        <div className={`main-pilot-row`}>
                            <div></div>
                            <div className="main-name"></div>
                            <div className="main-team-logo"></div>
                            <div className="main-team"></div>
                            <div></div>
                            <div className="main-races">Races</div>
                            <div className="main-points">Points</div>

                        </div>

                        {seasonPilots.map((pilot, index) => {

                            // if(pilot.fl){
                            //     pilotfl = pilot;
                            // }
                            if(index===limit)return (

                            <div className="view-full-table">
                                <Link to={`/pilotstanding/${idSeason}`}>View Full Table</Link>

                            </div>
                            )
                            if(index>limit) return null;
                            ////console.log(pilot)
                            ////console.log(seasonResults[stageNumber].resultlist)








                            const avatarFinded = _.find(pilot.pilot.avatar, {name: `${idSeason}`});
                            //console.log(pilot.pilot.avatar)
                            //console.log(avatarFinded)

                            return (
                                <div key={index}>
                                <div className={`main-pilot-row  main-pilot-row-after-race ${index === pilotOnSide  ? 'main-first-row' : ''}`} key={index} onClick={()=>{OpenInfo(index+1)}}>
                                    <div className="main-place">{index+1}
                                        {/*{ PlaceChange(pilot.prevPosition, index + 1)}*/}
                                    </div>
                                    <div className="main-name"> <span className="fn"> {pilot.pilot.firstname} </span> <span className="n"> {pilot.pilot.Name}</span></div>
                                    <div className={`main-team-logo main-team-logo-${pilot.team.shorttitle}`}><img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team.Logo.url}`} alt="" /></div>
                                    <div className={`main-team main-team-${pilot.team.shorttitle}`}>{pilot.team.name}</div>
                                    <div></div>
                                    <div className="main-races">0</div>
                                    <div className="main-points">0</div>

                                </div>
                                    <div className={`driver-info ${listNumberDriver === index+1 ? `driver-info-opened` : `driver-info-closed`} driver-info-${pilot.team.shorttitle}`}>
                                        <img src={`https://f1admin.serdyuks.com${(avatarFinded ? avatarFinded.url : pilot.pilot.avatar[0].url)}`} alt="" className={`driveravatar`} />

                                        <div className="driver-info-name">
                                            {pilot.pilot.firstname}
                                        </div>
                                        <div className="driver-info-nickname">
                                            {pilot.pilot.Name}

                                        </div>
                                        <div className="driver-info-number outline">{pilot.pilot.number}
                                        </div>






                                    </div>
                                </div>
                            )

                        })}
                        {/*<div className="web-row-fl"><span className="fl">Fastest lap</span> <span className="name">{pilotfl.pilot.firstname} {pilotfl.pilot.Name}</span> <span>{pilotfl.team.name} </span> <span>{pilotfl.timelap}</span> <span>+1 point</span>  </div>*/}
                    </div>

                </div>
            </div>
        </div>
        </>
    )

    /*--------------------------------------------------------------------------------*/

}

export default StandingTable;