import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useContext} from 'react';
import FormulaContext from "../Context/F1Context";
import HomeIcon from '@mui/icons-material/Home';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
//import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
const TopMenuNewMobile = ({season})=>{
    const {getNextStageId, nextGp} = useContext(FormulaContext);

    useEffect(() => {
        getNextStageId();
    }, []);

    if(!season){season = 6}
    const location = useLocation(); // React Hook
    //console.log(location.pathname);
    return(
        <div className="menumobile-bg" id='menu-color'>
            <Link className="menu-button" to={`/`}><HomeIcon /></Link>

            {/*<Link className="menu-button" to={`/f1`}>Main</Link>*/}

            <Link className={`menu-button ${location.pathname.match('stage') && 'menu-button-active'}`} to={`/stage/${nextGp[0] ? nextGp[0].stage : ''}`}><InfoIcon /></Link>



            {/*<Link className="menu-button" to={`/season/${season}`}>Season {season}</Link>*/}
            <Link className={`menu-button ${location.pathname.match('calendar') && 'menu-button-active'}`} to={`/calendar/${season}`}><CalendarMonthIcon/></Link>

            <Link className={`menu-button ${location.pathname.match('pilotstanding') && 'menu-button-active'}`} to={`/pilotstanding/${season}`}><SportsMotorsportsIcon/></Link>

            <Link className={`menu-button ${location.pathname.match('constru') && 'menu-button-active'}`} to={`/constructorstanding/${season}`}><EmojiEventsIcon /></Link>

            <Link className={`menu-button ${location.pathname.match('weather') && 'menu-button-active'}`} to={`/weatherchoose`}><WbSunnyIcon /></Link>
            {/*<Link className="menu-button" to={`/pilots/${season}`}>Pilots</Link>*/}
            {/*<span>|</span>*/}
            {/*<Link className="menu-button" to={`/rating`}>Rating</Link>*/}
        </div>
    )
}
export default TopMenuNewMobile;