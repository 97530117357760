const DetailPilot = (pilot, results, season)=>{
let pilotDetails = {
    name: '',
    races: 0,
    wins: 0,
    polePosition: 0,
    points: 0,
    podiums: 0,
    dnf: 0,
    highestPosition: 20,
    highestPositionCount: 1,
    fl: 0,
};

    let pilotSeasonDetails = {
        name: '',
        races: 0,
        wins: 0,
        polePosition: 0,
        points: 0,
        podiums: 0,
        dnf: 0,
        highestPosition: 20,
        highestPositionCount: 1,
        fl: 0,
    };

    results.forEach((race, index)=>{
let seasonWins = 0;

        if(race.season == season) {

            const pilotResSeason = race.resultlist.find(pilotresult => pilotresult.pilot.pilot.id === pilot.id);
            if(pilotResSeason) {
                //console.log(pilot.id, pilotResSeason)
                pilotSeasonDetails = {
                    name: pilot.Name,
                    races: pilotSeasonDetails.races + 1,
                    wins: pilotResSeason.race.finish == 1 ? pilotSeasonDetails.wins + 1 : pilotSeasonDetails.wins,
                    polePosition: pilotResSeason.qualify ? (pilotResSeason.qualify.finish == 1 ? pilotSeasonDetails.polePosition + 1 : pilotSeasonDetails.polePosition) : pilotSeasonDetails.polePosition,
                    points: pilotSeasonDetails.points + pilotResSeason.race.points,
                    podiums: pilotResSeason.race.finish<4 ? pilotSeasonDetails.podiums + 1 : pilotSeasonDetails.podiums,
                    dnf: pilotResSeason.race.dnf ? pilotSeasonDetails.dnf + 1 : pilotSeasonDetails.dnf,
                    highestPositionCount: pilotResSeason.race.finish < pilotSeasonDetails.highestPosition ? 1 : (pilotResSeason.race.finish == pilotSeasonDetails.highestPosition ? pilotSeasonDetails.highestPositionCount + 1 : pilotSeasonDetails.highestPositionCount),
                    highestPosition: pilotResSeason.race.finish < pilotSeasonDetails.highestPosition ? pilotResSeason.race.finish : pilotSeasonDetails.highestPosition,
                    fl: pilotResSeason.race.fastestlap ? pilotSeasonDetails.fl + 1 : pilotSeasonDetails.fl,

                }

                //console.log('season', pilotSeasonDetails)
            }
        }
        //const ResSeason = race.find(stage => stage.season == season);

        const pilotRes = race.resultlist.find(pilotresult => pilotresult.pilot.pilot.id === pilot.id);
        //console.log(race)


        if(pilotRes) {
            //console.log(pilot.id, pilotRes)

            pilotDetails = {
                name: pilot.Name,
                races: pilotDetails.races + 1,
                wins: pilotRes.race.finish == 1 ? pilotDetails.wins + 1 : pilotDetails.wins,
                polePosition: pilotRes.qualify ? (pilotRes.qualify.finish == 1 ? pilotDetails.polePosition + 1 : pilotDetails.polePosition) : pilotDetails.polePosition,
                points: pilotDetails.points + pilotRes.race.points,
                podiums: pilotRes.race.finish<4 ? pilotDetails.podiums + 1 : pilotDetails.podiums,
                dnf: pilotRes.race.dnf ? pilotDetails.dnf + 1 : pilotDetails.dnf,
                highestPositionCount: pilotRes.race.finish < pilotDetails.highestPosition ? 1 : (pilotRes.race.finish == pilotDetails.highestPosition ? pilotDetails.highestPositionCount + 1 : pilotDetails.highestPositionCount),
                highestPosition: pilotRes.race.finish < pilotDetails.highestPosition ? pilotRes.race.finish : pilotDetails.highestPosition,
                fl: pilotRes.race.fastestlap ? pilotDetails.fl + 1 : pilotDetails.fl,

            }

            //console.log('all', pilotDetails)
        }

    })

    // console.log('season', pilotSeasonDetails)
    // console.log('all', pilotDetails)
    return {season: pilotSeasonDetails, all: pilotDetails}
}

export default DetailPilot;