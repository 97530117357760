import React from 'react';
const Points = (position, fastlap, season, template)=>{

    //console.log(position, fastlap, season)


    if(season > 0){
    switch (position) {
        case 1:
            return 25+fastlap

        case 2:
            return 18+fastlap

        case 3:
            return 15+fastlap

        case 4:
            return 12+fastlap
        case 5:
            return 10+fastlap
        case 6:
            return 8+fastlap
        case 7:
            return 6+fastlap
        case 8:
            return 4+fastlap
        case 9:
            return 2+fastlap
        case 10:
            return 1+fastlap
        default:
            return 0

    }}

    // if(season == 4){
    //     if(template == 2) {
    //         switch (position) {
    //             case 1:
    //                 return 35+fastlap;
    //             case 2:
    //                 return 31+fastlap;
    //             case 3:
    //                 return 27+fastlap;
    //             case 4:
    //                 return 24+fastlap;
    //             case 5:
    //                 return 22+fastlap;
    //             case 6:
    //                 return 20+fastlap;
    //             case 7:
    //                 return 18+fastlap;
    //             case 8:
    //                 return 16+fastlap;
    //             case 9:
    //                 return 14+fastlap;
    //             case 10:
    //                 return 12+fastlap;
    //             case 11:
    //                 return 10;
    //             case 12:
    //                 return 9;
    //             case 13:
    //                 return 8;
    //             case 14:
    //                 return 7;
    //             case 15:
    //                 return 6;
    //             case 16:
    //                 return 5;
    //             case 17:
    //                 return 4;
    //             case 18:
    //                 return 3;
    //             case 19:
    //                 return 2;
    //             case 20:
    //                 return 1;
    //             default:
    //                 return 0;
    //
    //         }}
    //     if(template==0 || template==1) {
    //         switch (position) {
    //             case 1:
    //                 return 25 + fastlap
    //
    //             case 2:
    //                 return 18 + fastlap
    //
    //             case 3:
    //                 return 15 + fastlap
    //
    //             case 4:
    //                 return 12 + fastlap
    //             case 5:
    //                 return 10 + fastlap
    //             case 6:
    //                 return 8 + fastlap
    //             case 7:
    //                 return 6 + fastlap
    //             case 8:
    //                 return 4 + fastlap
    //             case 9:
    //                 return 2 + fastlap
    //             case 10:
    //                 return 1 + fastlap
    //             default:
    //                 return 0
    //
    //         }
    //     }
    //
    //
    //
    //         // if(position == 1)
    //         //     return 35+fastlap;
    //         // if(position == 2)
    //         //     return 31+fastlap;
    //         // if(position == 3)
    //         //     return 27+fastlap;
    //         // if(position == 4)
    //         //     return 24+fastlap;
    //         // if(position == 5)
    //         //     return 22+fastlap;
    //         // if(position == 6)
    //         //     return 20+fastlap;
    //         // if(position == 7)
    //         //     return 18+fastlap;
    //         // if(position == 8)
    //         //     return 16+fastlap;
    //         // if(position == 9)
    //         //     return 14+fastlap;
    //         // if(position == 10)
    //         //     return 12+fastlap;
    //         // if(position == 11)
    //         //     return 10;
    //         // if(position == 12)
    //         //     return 9;
    //         // if(position == 13)
    //         //     return 8;
    //         // if(position == 14)
    //         //     return 7;
    //         // if(position == 15)
    //         //     return 6;
    //         // if(position == 16)
    //         //     return 5;
    //         // if(position == 17)
    //         //     return 4;
    //         // if(position == 18)
    //         //     return 3;
    //         // if(position == 19)
    //         //     return 2;
    //         // if(position == 20)
    //         //     return 1;
    //
    //
    //     }

}
export default Points;