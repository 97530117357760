import {Link, useParams,useLocation} from "react-router-dom";
import React from 'react';

const TopMenuSeasons = ({seasons, season})=>{
    // if(!season){season = 4}
    let params = useParams();

    return(
        <div className="menu-bg" >
            {seasons.map((season) => {
                //console.log(window.location.pathname)
                return (
                    <Link className={`menu-button ${season.id == params.IdSeason ? 'menu-button-active-season' : ''}`} key={season.id} to={`/season/${season.id}`} >{season.season}</Link>
                )
            }) }
        </div>
    )
}
export default TopMenuSeasons;