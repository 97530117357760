import React, { useContext, useState } from 'react';
import {useLocation} from "react-router-dom";
// import PortfolioContext from '../../context/PortfolioContext';

const BackgroundImage = (props) => {
   // const { randomBackground } = useContext(PortfolioContext);
    const [isLoading, setIsLoading] = useState(true);

    const LoadingSpinner = () => {
        setInterval(() => {
            setIsLoading(false);
        }, 1000);
    };
    const {pathname} = useLocation();
    return (
        <img
            className={`first-open-page-background ${isLoading ? 'blank' : 'visible'} ${pathname.startsWith('/web') ? 'web-background' : ''}`}
            //src={`${process.env.REACT_APP_ADMIN_URL}${randomBackground.picture.formats.xlarge.url}`}
            src={`${process.env.REACT_APP_ADMIN_URL}/uploads/F1_2022_Alpha_Tauri_3_4_Left_d7460671e9.jpg`}
            onLoad={LoadingSpinner}
        />
    );
};
export default BackgroundImage;
