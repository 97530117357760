import logo from './logo.svg';
import './App.scss';

import MainPage  from './Components/MainPage';
import WebPilotStanding from "./Components/WebPilotStanding";
//import React from "@types/react";

function App() {
  return (<MainPage/>);
}

export default App;
