import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Link, useParams} from "react-router-dom";

import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";

import GetAvatar from "./GetAvatar";

import './Web.scss';
import Points from "./Points";





const WebPilotStanding = () => {
    let params = useParams();
    const {getSeasonRes, seasonResultsLoading, seasonResults} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);
    const [testShow, setTestShow] = useState(true);
    const [pilotOnSide, setPilotOnSide]= useState(0);



    const driverStandingList = useMemo(()=>{

        let pilotlist = [];

        seasonResults.forEach((stage, index) => {
            // console.log(stage);

            if(!stage.test || testShow){
                stage.resultlist.forEach((pilotRes, index) => {


                    if (pilotRes.race.points >= 0) {
                        const pilotresults = _.find(pilotlist, {pilot: pilotRes.pilot.pilot.Name});
                        if (pilotRes.pilot.pilot.AI && pilotsAI || !pilotRes.pilot.pilot.AI) {
                            if (!pilotresults) {

                                pilotlist.push({
                                    pilot: pilotRes.pilot.pilot.Name,
                                    number: pilotRes.pilot.pilot.number,
                                    team: pilotRes.pilot.team.name,
                                    avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                    races: 1,
                                    team_full: pilotRes.pilot.team,

                                    points: Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0)
                                })
                            }
                            if (pilotresults) {

                                pilotlist.forEach((driver, index) => {
                                    let sprintpoints;
                                    if (!pilotRes.sprint) {
                                        sprintpoints = 0;
                                    }
                                    if (pilotRes.sprint) {
                                        sprintpoints = pilotRes.sprint.points;
                                    }
                                    if (driver.pilot === pilotresults.pilot) {
                                        pilotlist[index] = {
                                            pilot: driver.pilot,
                                            number: driver.number,
                                            avatar: GetAvatar(pilotRes.pilot.pilot.avatar, params.IdSeason),
                                            team: pilotRes.pilot.team.name,
                                            races: driver.races + 1,
                                            team_full: pilotRes.pilot.team,

                                            points: driver.points + Points(pilotRes.race.finish, pilotRes.race.fastestlap ? 1 : 0, params.IdSeason, 0) + sprintpoints
                                        }
                                    }
                                })
                            }
                        }
                    }

                })
            }

        })
        const pointslist = _.sortBy(pilotlist, [function (o) {
            return o.points;
        }]);

        const driverStandingList = _.orderBy(pointslist, ['points'], ['desc']);
        return driverStandingList;
    },[seasonResults]);

    useEffect(() => {
        getSeasonRes(params.IdSeason);

    }, []);








        useEffect(() => {

        if(seasonResultsLoading) return


            const intervalPilot = setInterval(()=> {
                setPilotOnSide((prevPilot)=>{
                    // console.log(driverStandingList.length)
                    // console.log(prevPilot)
                    if(19 === prevPilot+1) return 0;
                    console.log(prevPilot)
                    return prevPilot+1;
                })
            }, 3000);
            console.log('start intertval', intervalPilot)


            return () => {
                console.log('clear intertval', intervalPilot)
                clearInterval(intervalPilot);

            }
        }, [seasonResultsLoading]);




        if (seasonResultsLoading) {
            return (
                <div><CircularProgress color="inherit"/></div>
            )
        }
        return (
                <div className="web-calendar">

                    <div className="web-calendar-container">

                        <div className={`web-first-place web-first-place-${driverStandingList[pilotOnSide].team_full.shorttitle}`}>
                            <div className="web-team-name-vertical">{driverStandingList[pilotOnSide].team}</div>
                            <div className="web-pilot-place">{pilotOnSide+1}st</div>
                            <div className="web-pilot-number outline">{driverStandingList[pilotOnSide].number}</div>
                            <div className="web-pilot-div-avatar"><img src={`${process.env.REACT_APP_ADMIN_URL}${driverStandingList[pilotOnSide].avatar}`} alt="" className='web-pilot-avatar' /></div>


                        </div>
                        <div className='web-table-list'>
                            <div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                            <div className="web-league-under-title">Driver Standing <span className="web-season"> Season {params.IdSeason}</span></div>
                            <div className="web-league-list ">

                                <div className={`web-pilot-row`}>
                                    <div></div>
                                    <div className="web-name"></div>
                                    <div className="web-team-logo"></div>
                                    <div className="web-team"></div>
                                    <div className="web-races">Races</div>
                                    <div className="web-points">Points</div>

                                </div>

                                {driverStandingList.map((pilot, index) => {


                                    return (
                                        <div className={`web-pilot-row  ${index === pilotOnSide  ? 'first-row' : ''}`} key={index}>
                                            <div className="web-place">{index + 1}</div>
                                            <div className="web-name">{pilot.pilot}</div>
                                            <div className={`web-team-logo web-team-logo-${pilot.team_full.shorttitle}`}><img className="web-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.team_full.Logo.url}`} alt="" /></div>
                                            <div className={`web-team web-team-${pilot.team_full.shorttitle}`}>{pilot.team}</div>
                                            <div className="web-races">{pilot.races}</div>
                                            <div className="web-points">{pilot.points}</div>

                                        </div>
                                    )

                                })}








                            </div>
                        </div>













                    </div>
                </div>
            )

        /*--------------------------------------------------------------------------------*/

}

export default WebPilotStanding;