import React, {useContext, useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';

import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import FormulaContext from "../Context/F1Context";
import './CalendarMain.scss';
import './PodiumAvatars.scss';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopMenu from "./TopMenu";
import GetAvatar from "./GetAvatar";


const CalendarOnMain = ({seasonNumber}) => {
    //let params = useParams();

    const {getStages, stages, stagesLoading,seasonResultsLoading, seasondetails,getSeasonRes,seasonResults, dispatch} = useContext(FormulaContext);
    const matches = useMediaQuery('(max-width:450px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');
    const [openStage, setOpenedCalendarStage] = useState(-1);
    useEffect(() => {
        getStages(seasonNumber);
    }, []);
    useEffect(() => {
        getSeasonRes(seasonNumber);
        // return function cleanup() {
        //     dispatch({type: 'CLEAR_RESULTS'});
        // };
    }, []);




    if (seasonResultsLoading) return (<div className="calendar">
        <div className="title-page-background"><Link className="title-page" to={`/season/${seasonNumber}`}> {seasondetails.season}</Link>
        </div>
        <div className="thumbnail-list"><CircularProgress color="inherit"/></div>
    </div>)
    if (seasonResultsLoading === false) {


    const OpenCalendarStage = (i)=>{
        if(openStage == i) setOpenedCalendarStage(-1);
        if(openStage != i) setOpenedCalendarStage(i);
    }



        //console.log(seasonResults)
        return (
            <div className="main-calendar">
                {/*<TopMenu season={season} />*/}
                {/*<div className="title-page-background"><Link className="title-page" to={`/season/${season}`}>{seasondetails.season}</Link>*/}
                {/*</div>*/}

                <div className="stage-list">







                    {seasonResults.map((stage, index) => {
                        const date = moment(stage.stage.date, "YYYY-MM-DD");

                        console.log(stage.stage)
                        return(


                        <div className={`stage ${openStage == index ? 'stage-open': ''}`} key={index} onClick={()=>{
                            OpenCalendarStage(index);
                        }}>
                            <div className="stage-content">
                            <div className="flag"><img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.logo.url}`} alt=""
                                                       className="img"/></div>
                            <div className="country">{stage.track.Country}</div>
                            <div className="title">{stage.laps} laps {stage.sprint ? "+ Sprint" : ""}</div>
                                {openStage != index ? <div className="date-d">{date.format('DD')}</div> : ''}
                                {openStage != index ? <div className="date-m">{date.format('MMM')}</div> : ''}

                                {openStage == index ? <div className="date-m">{date.format('DD')} - {date.format('MMM')}</div> : ''}
                                {openStage == index ? <div className="image"><img src={`${process.env.REACT_APP_ADMIN_URL}${stage.track.TrackImage.url}`} alt=""
                                                                                  className="img"/></div> : ''}
                                {openStage == index ? (stage.stage.done ?  <Link to={`/stage/${stage.stage.stage}`} className="button">Results</Link>: '')  : ''}
                            </div>
                        </div>
                        )





                    })}



                </div>
            </div>
        )
    }
}
export default CalendarOnMain;