
import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import FormulaContext from "../Context/F1Context";
import _ from 'lodash';
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TopMenu from "./TopMenu";
import TopMenuNew from "./TopMenuNew";
import TopMenuSeasons from "./TopMenuSeasons";
import Points from "./Points";
import './Content2.scss';
import TyresStrategy from "./TyresStrategy";
import GetAvatar from "./GetAvatar";
import TopMenuNewMobile from "./TopMenuNewMobile";


const ConstructorStanding = () => {
    let params = useParams();
    const {seasons, getSeason,getSeasonRes, seasonResultsLoading, seasonResults} = useContext(FormulaContext);
    const [pilotsAI, setPilotsAI] = useState(false);

    const [stageShow, setStageShow] = useState(0);
    const [testShow, setTestShow] = useState(false);
    const [partShow, setPartShow] = useState(1);
    const [listNumberDriver, setNumberDriver]= useState(0);
    const [stageNumber, setStageNumber] = useState(0)


    const matches = useMediaQuery('(max-width:650px)');
    const matches1500 = useMediaQuery('(max-width:1500px)');
    const matches800 = useMediaQuery('(max-width:800px)');
    let teamlist = [];
    useEffect(() => {
        getSeasonRes(params.IdSeason);
        getSeason();
    }, [])

    const ChangeAI = () => {
        setPilotsAI(!pilotsAI);
    }
    const elementsCount = ()=>{return 8;}
    if (seasonResultsLoading) {
        //console.log(seasonResults);
        return (

            <div><CircularProgress color="inherit"/></div>
        )
    }
    if (!seasonResultsLoading) {



        //console.log(seasonResults);

        // console.log(seasonResults);

        seasonResults.forEach((stage, index)=>{
            if(!stage.test || testShow){
                //console.log(stage);
                stage.resultlist.forEach((teamPilotRes, index) => {
                    //console.log(teamPilotRes);


                    if (teamPilotRes.race.start >= 0) {
                        //console.log(pilotRes.pilot.pilot.Name, pilotRes.race.points);
                        const teamresults = _.find(teamlist, {team: teamPilotRes.pilot.team.name});
                        let pp = Points(teamPilotRes.race.finish, (teamPilotRes.race.fastestlap ? 1 : 0), params.IdSeason, 0);
                        if (teamPilotRes.race.dnf) { pp=0}
                        let sprintpoints = 0;
                        if(teamPilotRes.pilot.pilot.AI && !pilotsAI) {return null}
                        if (!teamresults) {

                            if (teamPilotRes.sprint) {
                                sprintpoints = teamPilotRes.sprint.points;
                            }

                            let driver1 = teamPilotRes.pilot.pilot.Name ? teamPilotRes.pilot.pilot : '';

                            teamlist.push({
                                team: teamPilotRes.pilot.team.name,
                                shorttitle: teamPilotRes.pilot.team.shorttitle,
                                logo: teamPilotRes.pilot.team.Logo.url,
                                //races: 1,
                                points: pp + sprintpoints,
                                drivers: teamPilotRes.pilot.pilot,
                                driver1: driver1,
                                driver2: '',
                                driver1Points: pp,
                                driver2Points: 0,
                            })
                        }
                        if (teamresults) {
                            teamlist.forEach((car, index) => {
                                // console.log(pilotRes.sprint.points);


                                if (teamPilotRes.sprint) {
                                    sprintpoints = teamPilotRes.sprint.points;
                                }
                                if (car.team === teamresults.team) {
                                    let driver1;
                                    let driver2 = car.driver2;
                                    if(!car.driver2 && car.driver1.Name != teamPilotRes.pilot.pilot.Name ) driver2 = teamPilotRes.pilot.pilot;

                                    teamlist[index] = {

                                        team: teamPilotRes.pilot.team.name,
                                        shorttitle: teamPilotRes.pilot.team.shorttitle,
                                        logo: teamPilotRes.pilot.team.Logo.url,
                                        //races: driver.races + 1,
                                        points: car.points + pp + sprintpoints,
                                        driver: car.driver + ' / ' +  teamPilotRes.pilot.pilot,
                                        driver1: (car.driver1 ? car.driver1 : teamPilotRes.pilot.pilot),
                                        driver2: driver2,
                                        driver1Points: car.driver1Points + (car.driver1.Name == teamPilotRes.pilot.pilot.Name  ? pp : 0),
                                        driver2Points: car.driver2Points + (driver2.Name == teamPilotRes.pilot.pilot.Name  ? pp : 0),

                                    }
                                }
                            })
                            //console.log(pilotlist);
                        }
                        //}
                        //console.log(pilotresults);
                        //console.log(pilotlist);
                    }

                })

            }

        })
        const pointslist = _.sortBy(teamlist, [function (o) {
            return o.points;
        }]);
        const ChangeAI = ()=>{
            setPilotsAI(!pilotsAI);
        }
        const ChangeTest = ()=>{
            setTestShow(!testShow);
        }
        const prevGP = ()=>{
            const idGP = stageShow-1;

            setStageShow(idGP)
        }
        const nextGP = ()=>{
            const idGP = stageShow+1;

            setStageShow(idGP)
        }

        const prevPartGP = () => {
            const idGP = partShow - 1;

            setPartShow(idGP)
        }
        const nextPartGP = () => {
            const idGP = partShow + 1;

            setPartShow(idGP)
        }

        const sRcount = seasonResults.length;
        const p = sRcount % elementsCount() ? 1 : 0;
        //console.log(p)
        const partCount = p + Math.trunc(sRcount / elementsCount())



        const OpenInfo = (listNumber)=>{
            if(listNumber === listNumberDriver) setNumberDriver(0);
            if(listNumber != listNumberDriver) setNumberDriver(listNumber);
            //console.log(seasonResults.length)
            setStageNumber(seasonResults.length-1);
        }



        const StagesColumns = ({teamName,stages})=>{

            /*--------------------------------------------*/
            let partStagesList = [];
            const partStages = (part)=>{
                seasonResults.forEach((stage, index)=>{
                    if(index < part*elementsCount() && index >= (part-1)*elementsCount()){
                        partStagesList.push(stage)
                    }
                })
                return partStagesList;
            }
            partStages(partShow);
                return (
                    partStagesList.map((sc, index) => {
                        if(!sc.stage.test || testShow) {
                            if (stages) {

                                return (
                                    <Link to={`/stage/${sc.stage.stage}`} key={index}>
                                        <img src={`${process.env.REACT_APP_ADMIN_URL}${sc.track.logo.url}`} alt=""
                                             className={`country-icon ${sc.stage.done ? '' : 'not-done'}`}/>
                                    </Link>
                                );

                            }
                            //console.log(sc)



                            const teamCheck = _.find(sc.resultlist, function (o) {
                               // console.log(o.pilot.team.name+' - '+teamName)
                                return o.pilot.team.name == teamName;
                            });
                            //console.log(pilotCheck)
                            // const aiCheck = _.find(sc.resultlist, function (o) {
                            //     return o.pilot.pilot.AI === true;
                            // });
                            //
                            // if (aiCheck && !pilotsAI) {
                            //     return (<div className='points-gp'>X</div>)
                            // }
                            console.log(teamCheck)
                            if (!teamCheck) {
                                return (<div className='points-gp'>X</div>)
                            }

                            return (

                                <div key={index} className="points-gp">{sc.resultlist.map((stg, i) => {

                                    if (!stages) {



                                        // const pilot = _.find(stg.pilot.pilot, { Name: pilotName });
                                        // if(!pilot) {
                                        //     return (<div key={i}>X</div>)
                                        // }
                                        if (teamName === stg.pilot.team.name) {
                                            if(stg.pilot.pilot.AI  && !pilotsAI){ return (<div className='points-gp'>X</div>)}
                                            //if(!stg.pilot.pilot){ return (<div className='points-gp'>X</div>)}
                                            // const poleposition = () => {
                                            //     if (stg.qualify) {
                                            //
                                            //         if (stg.qualify.finish === 1) {
                                            //             return 'fl'
                                            //         }
                                            //
                                            //     }
                                            // }
                                            const poleposition = () => {
                                                if (stg.qualify) {
                                                    if (!stg.qualify) {
                                                        return ''
                                                    }
                                                    if (stg.qualify.finish === 1) {
                                                        return 'pp'
                                                    }
                                                }
                                            }
                                            const fastestlap = () => {
                                                if (stg.race.fastestlap) {

                                                    if (stg.race.fastestlap) {
                                                        return 'fl'
                                                    }
                                                }
                                            }
                                            //console.log(Points(stg.race.finish, (stg.race.fastestlap ? 1 : 0), params.IdSeason, 0))

                                            return (<div key={i} style={{display: "inline-block", paddingRight: '5px', fontSize: '10px'}}
                                                         className={`${(poleposition ? poleposition() : '')} ${(fastestlap ? fastestlap() : '')}`}>{stg.race.dnf ? 'DNF' : Points(stg.race.finish, (stg.race.fastestlap ? 1 : 0), params.IdSeason, 0)}</div>)
                                        }
                                    }

                                })}</div>
                            )
                        }
                    }))

            /*--------------------------------------------*/

            /*--------------------------------------------*/
        }

        /*--------------------------------------------*/


        /***********************************/ /***********************************/ /***********************************/
        const DriverInfo = ({driver})=>{



            const resList = seasonResults[stageNumber].resultlist;
            //console.log(seasonResults[stageNumber])
            //console.log(resList.length)
            if(resList.length == 0) setStageNumber(stageNumber-1)
            const driverItem = resList.map((pilotFromArray)=>{
                //console.log(pilotFromArray.pilot.pilot.Name+' - '+driver)

                if(pilotFromArray.pilot.pilot.Name == driver){
                    //console.log('true')

                    return (
                        <>

                            <div className="driver-info-stage-container-position">Position: {pilotFromArray.race.finish}
                            </div>
                            <div
                                className="driver-info-stage-container-points">Points: {Points(pilotFromArray.race.finish, pilotFromArray.race.fastestlap ? 1 : 0, params.IdSeason, 0)}
                            </div>
                            <div className="driver-info-stage-container-start">Start: {pilotFromArray.race.start}
                            </div>

                            <div className='strategy-title'>Strategy:</div>
                            <div className="strategy-block">

                                {TyresStrategy(pilotFromArray.race.starttyre, 0, seasonResults[stageNumber].laps)}
                                {pilotFromArray.pitstop.map((pits, index) => {

                                    return (<div
                                        key={index}>{TyresStrategy(pits.tyre, pits.lap, seasonResults[stageNumber].laps, pits.time, pits.repair)}</div>)
                                })}</div>
                        </>
                    )
                }
                return null;
            })
            return driverItem;
        }

        /******************************************/


        const TeamInfo = ({driver, number, points})=>{

            return(
                <>
                    <img src={`https://f1admin.serdyuks.com${GetAvatar(driver.avatar, params.IdSeason, 1)}`} alt="" className={`driveravatar ${number == 1 ? 'driveravatar-d1' : 'driveravatar-d2'}`} />
                    <div className={`teamdriver-info-points`}>Points</div>
                    <div className={`teamdriver-info-points ${number == 1 ? 'teamdriver-info-points-d1' : 'teamdriver-info-points-d2'}`}>{points}</div>
                    <div className={`teamdriver-info-name ${number == 1 ? 'teamdriver-info-name-d1' : 'teamdriver-info-name-d2'}`}>
                        {driver.firstname}
                    </div>
                    <div className={`teamdriver-info-nickname ${number == 1 ? 'teamdriver-info-nickname-d1' : 'teamdriver-info-nickname-d2'}`}>
                        {driver.Name}

                    </div>
                    <div className={`teamdriver-info-number outline ${number == 1 ? 'teamdriver-info-number-d1' : 'teamdriver-info-number-d2'}`}>{driver.number}
                    </div>
                </>
            )
        }

        /***********************************/ /***********************************/


        /***********************************/

       if (matches800) {
           return (
               <div className="contentmobile">

                   <div className="contentmobile-container">
                       <div className='main-table-list main-table-list-mobile'>
                           <div className="main-league-under-title main-league-under-title-mobile">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                           <TopMenuNewMobile season={params.IdSeason} />
                           <TopMenuSeasons  seasons={seasons} season={params.IdSeason} />
                           <div>Перегляд на телефоні тимчасово недоступний
                               <CircularProgress color="inherit"/></div>

                           <div className='main-table-list main-table-list-mobile'>
                               {/*<div className="web-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>*/}
                               <div className="main-league-under-title main-league-under-title-mobile">Constructor Standing <span className="main-season"> Season {params.IdSeason}</span></div>
                           </div>

                   </div></div></div>


           )
       }

            return (
                <>






                    <div className="content">

                        <div className="content-container">


                            <div className='main-table-list'>
                                <div className="main-league-title">F1 Ukrainian PlaceToPlay League #F1UAPTPLeague</div>
                                <TopMenuNew season={params.IdSeason} />
                                <TopMenuSeasons  seasons={seasons} season={params.IdSeason} />
                                {/*<div className="menu-bg" id='menu-color'>*/}
                                {/*    {seasons.map((season) => {*/}
                                {/*        return (*/}
                                {/*            <Link className="menu-button" key={season.id} to={`/season/${season.id}`} >{season.season}</Link>*/}
                                {/*        )*/}
                                {/*    }) }*/}
                                {/*</div>*/}
                                <div className="main-league-under-title">Constructor Standing <span className="main-season"> Season {params.IdSeason}</span></div>
                                <div className="main-league-list main-league-list-after-race main-league-list-constructors">


                                    <div className={`main-pilot-row`}>
                                        <div></div>
                                        <div className="main-name"></div>
                                        <div className="main-team-logo"></div>
                                        <div className="main-team"></div>
                                        <div className='header-gp-with-buttons'>
                                            <div>
                                                <ArrowLeftIcon sx={{
                                                color: '#222222',
                                                padding: '0px',
                                                display: (partShow > 1 ? 'block' : 'none'),
                                                cursor: 'pointer',
                                                width: '25px'
                                            }} onClick={prevPartGP}/>
                                            </div>
                                            <StagesColumns pilotName={false} stages={true}/>
                                            <div>
                                                <ArrowRightIcon sx={{
                                                color: '#222222',
                                                padding: '0px',
                                                display: (partShow < partCount ? 'block' : 'none'),
                                                cursor: 'pointer',
                                                width: '25px'
                                            }} onClick={nextPartGP}/>
                                            </div>
                                        </div>
                                        <div className="main-races">Races</div>
                                        <div className="main-points">Points</div>

                                    </div>

                                    {_.orderBy(pointslist, ['points'], ['desc']).map((pilot, index) => {

                                        // if(pilot.fl){
                                        //     pilotfl = pilot;
                                        // }

                                        //console.log(pilot.team)
                                        console.log(pilot)
                                        return (
                                            <div key={index}>
                                            <div className={`main-pilot-row  main-pilot-row-after-race `} key={index} onClick={()=>{OpenInfo(index+1)}}>
                                                <div className="main-place">{index + 1}</div>
                                                <div className="main-name" style={{fontSize: '10px', textTransform: "uppercase"}}> <span className="fn" style={{fontSize: '10px', textTransform: "uppercase"}}>{pilot.driver1.Name}</span> / <span className="fn" style={{fontSize: '10px', textTransform: "uppercase"}}>{pilot.driver2.Name}</span></div>
                                                <div className={`main-team-logo main-team-logo-${pilot.shorttitle}`}>
                                                    <img className="main-team-logo-img" src={`${process.env.REACT_APP_ADMIN_URL}${pilot.logo}`} alt="" />
                                                </div>
                                                <div className={`main-team main-team-${pilot.shorttitle}`}>{pilot.team}</div>
                                                <div className="table-gp-without-buttons">
                                                    <div></div>
                                                    <StagesColumns teamName={pilot.team} stages={false}/>

                                                    <div></div>
                                                </div>
                                                <div className="main-races"></div>
                                                <div className="main-points">{pilot.points}</div>

                                            </div>






                                                <div className={`teamdriver-info ${listNumberDriver === index+1 ? `teamdriver-info-opened` : `teamdriver-info-closed`} teamdriver-info-${pilot.shorttitle}`}>

                                                    {pilot.driver1 ? <TeamInfo driver={pilot.driver1} number={1} points={pilot.driver1Points}/> : ''}
                                                    {pilot.driver2 ? <TeamInfo driver={pilot.driver2} number={2} points={pilot.driver2Points}/> : ''}
                                                    {/*<>*/}
                                                    {/*<img src={`https://f1admin.serdyuks.com${GetAvatar(pilot.driver1.avatar, params.IdSeason, 1)}`} alt="" className={`driveravatar d1`} />*/}
                                                    {/*<div className="driver-info-name">*/}
                                                    {/*    {pilot.driver1.Name}*/}
                                                    {/*</div>*/}
                                                    {/*<div className="driver-info-nickname">*/}
                                                    {/*    {pilot.driver1.Name}*/}

                                                    {/*</div>*/}
                                                    {/*<div className="driver-info-number outline">{pilot.driver1.number}*/}
                                                    {/*</div>*/}
                                                    {/*    </>*/}


                                                    {/*<div className='driver-info-stage-container'>*/}
                                                    {/*    <div className="driver-info-stage-container-stage">*/}
                                                    {/*        /!*<div>*!/*/}
                                                    {/*        /!*    <ArrowLeftIcon sx={{*!/*/}
                                                    {/*        /!*        color: '#c2c2c2',*!/*/}
                                                    {/*        /!*        padding: '0px',*!/*/}
                                                    {/*        /!*        display: (stageNumber-1 > -1 ? 'block' : 'none'),*!/*/}
                                                    {/*        /!*        cursor: 'pointer',*!/*/}
                                                    {/*        /!*        width: '25px'*!/*/}
                                                    {/*        /!*    }} onClick={()=>{setStageNumber(stageNumber-1)}}/>*!/*/}
                                                    {/*        /!*</div>*!/*/}
                                                    {/*        <div className="stage">Stage</div>*/}
                                                    {/*        <div className="number">{stageNumber + 1}</div>*/}
                                                    {/*        <div className="driver-info-country">*/}
                                                    {/*            {seasonResults[stageNumber].track.Country}*/}
                                                    {/*        </div>*/}
                                                    {/*        <img*/}
                                                    {/*            src={`https://f1admin.serdyuks.com${seasonResults[stageNumber].track.logo.url}`}*/}
                                                    {/*            alt="" className="flag"/>*/}
                                                    {/*        /!*<div>*!/*/}
                                                    {/*        /!*    <ArrowRightIcon sx={{*!/*/}
                                                    {/*        /!*        color: '#c2c2c2',*!/*/}
                                                    {/*        /!*        padding: '0px',*!/*/}
                                                    {/*        /!*        display: ((stageNumber+1 < seasonResults.length && seasonResults[stageNumber+1].resultlist.length > 0 )? 'block'  : 'none'),*!/*/}
                                                    {/*        /!*        cursor: 'pointer',*!/*/}
                                                    {/*        /!*        width: '25px'*!/*/}
                                                    {/*        /!*    }} onClick={()=>{setStageNumber(stageNumber+1)}}/>*!/*/}
                                                    {/*        /!*</div>*!/*/}
                                                    {/*    </div>*/}

                                                    {/*    <DriverInfo driver={pilot.driver1}/>*/}
                                                    {/*</div>*/}




                                                </div>











                                            </div>
                                        )

                                    })}
                                    <div style={{fontSize: '12px', fontFamily: 'formula1 display', marginTop: '5px'}}><span className="fl"> * purple color - fastest lap</span>, <span
                                        className="pp"> underline - poleposition</span>,
                                        (points) - sprint points
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </>
            )

        /*--------------------------------------------*/

        /*--------------------------------------------*/
    }
}

export default ConstructorStanding;