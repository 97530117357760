const Tyres = (tyre, q)=>{
    switch (tyre) {
        case 'soft':
            return (<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/soft_e90144a430.png`} alt="Soft" className={`tyre-icon ${q}`} title={q}/>);
        case 'medium':
            return (<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/medium_048ad8ea7c.png`} alt="Medium" className={`tyre-icon ${q}`} title={q}/>);
        case 'hard':
            return (<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/hard_302a20b791.png`} alt="Hard" className={`tyre-icon ${q}`} title={q}/>);
        case 'int':
            return (<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/int_38e8ddb6b1.png`} alt="Inters" className={`tyre-icon ${q}`} title={q}/>);
        case 'wet':
            return (<img src={`${process.env.REACT_APP_ADMIN_URL}/uploads/wet_96e9dce19c.png`} alt="Wet" className={`tyre-icon ${q}`} title={q}/>);
        default:
            return '';
            

    }
}
export default Tyres;